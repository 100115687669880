<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div
      class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
      {{ $t("createUser.createUser") }}
    </div>
    <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
      <ul class="list-none w-12 p-0">
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.lastname") }} *</p>
          <InputText type="text" v-model="nom" :class="{ 'p-invalid': errorNom }" />
          <!-- <small class="p-error">{{
            errorNom ? "Un nom est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.name") }} *</p>
          <InputText type="text" v-model="prenom" :class="{ 'p-invalid': errorPrenom }" />
          <!-- <small class="p-error">{{
            errorPrenom ? "Un prénom est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center gap-2">
          <p class="font-medium text-900 w-3">{{ $t("createUser.gender") }}</p>
          <div class="flex align-items-center">
            <RadioButton v-model="civilite" inputId="femme" name="femme" value="MME" />
            <label for="femme" class="ml-2">{{ $t('createUser.woman') }}</label>
          </div>
          <div class="flex align-items-center">
            <RadioButton v-model="civilite" inputId="homme" name="homme" value="MR" />
            <label for="homme" class="ml-2">{{ $t('createUser.man') }}</label>
          </div>
          <!-- <small class="p-error">{{
            errorNom ? "Un nom est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.job") }}</p>
          <InputText type="text" v-model="metier" />
          <!-- <small class="p-error">{{
            errorPrenom ? "Un prénom est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.mail") }} *</p>
          <InputText type="text" v-model="mail" :class="{ 'p-invalid': errorMail }" />
          <!-- <small class="p-error">{{
            errorMail ? "Un mail est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.phone") }}</p>
          <InputText type="text" v-model="tel" />
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("createUser.password") }} *</p>
          <Password toggleMask type="text" :pt="{
            panel: { class: 'hidden' },
          }" v-model="password" :class="{ 'p-invalid': errorPassword }" />
          <!-- <small class="p-error">{{
            errorPassword ? "Un mot de passe est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <label for="role" class="font-medium text-900 w-3">{{ $t("createUser.role") }} *</label>
          <Dropdown v-model="selectedRole" :options="roles" optionLabel="name" name="role"
            :class="['w-full md:w-14rem', { 'p-invalid': errorRole }]" />
        </li>
        <li class="py-3">
          <small>{{ $t("createUser.requiredFields") }}</small>
        </li>
        <li>
          <div class="flex justify-content-center align-content-center">
            <Button type="submit"
              class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
              @click="creation()">{{ $t("comment.validate") }}</Button>
            <router-link :to="'/compte/1'" class="no-underline">
              <Button
                class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">{{
                  $t("comment.cancel") }}</Button>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <Toast />
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      idTiers: "",
      mail: "",
      prenom: "",
      nom: "",
      tel: "",
      metier: "",
      selectedRole: "",
      password: "",
      civilite: "",
      roles: [
        { name: this.$t('createUser.roleUser'), code: "1" },
        { name: this.$t('createUser.roleAdmin'), code: "2" },
      ],
      errorMail: false,
      errorPrenom: false,
      errorNom: false,
      errorRole: false,
      errorPassword: false,
    };
  },
  mounted() {
    this.idTiers = localStorage.getItem("idTiers");
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
  },
  methods: {
    creation() {
      this.errorMail = this.mail === "";
      this.errorPrenom = this.prenom === "";
      this.errorNom = this.nom === "";
      this.errorRole = this.selectedRole === "";
      this.errorPassword = this.password === "";
      if (
        !this.errorMail &&
        !this.errorPrenom &&
        !this.errorNom &&
        !this.errorRole &&
        !this.errorPassword
      ) {

        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/api/createUser",
            {
              idTiers: this.idTiers,
              mail: this.mail,
              password: this.password,
              nom: this.nom,
              prenom: this.prenom,
              metier: this.metier,
              tel: this.tel,
              role: this.selectedRole.code,
              civilite: this.civilite,
            },
            {
              headers: {
                //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+this.token,
              },
            }
          )
          .then((response) => {
            if(response.status === 200){
              this.$router.push("/compte/1");
            }
          }).catch((e) => {
            //Token invalide ou expiré
            if(e['response']['status'] === 401){
                this.$router.push("/login");
            }
            //capture des erreurs revoyées par le back-end
            else{
                this.$toast.add({
                    severity: "warn",
                    summary: this.$t('erreur.erreur'),
                    detail: this.$t('erreur.erreurInterne'),
                    life: 3000,
                });
            }
            this.errorMail = true;
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Attention",
          detail: "Au moins un champ obligatoire n'a pas été saisi",
          life: 3000,
        });
      }
    },
  },
};
</script>
