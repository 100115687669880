import { createI18n } from "vue-i18n";
import fr from "./fr.json";
import en from "./en.json";
import it from "./it.json";

const i18n = createI18n({
    locale: localStorage.getItem('appLanguage') || 'fr',
    messages: {
      fr,
      en,
      it
    }
  });

export default i18n;