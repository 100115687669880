<template>
  <div>
    <p class="text-4xl pr-4 pl-2 text-700">{{ $t("sideBar.myAccount") }}</p>
    <div class="card mb-4">
      <div class="w-full h-full p-2">
      <TabView 
      v-model:activeIndex="active"
      :pt="{
        panelContainer: () => ({
          class: 'p-0'
        }),
      }">
      <TabPanel :header="$t('compte.myContactDetails')" :pt="{
        header: () => ({
          class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
        })
      }" >
      <Coordonnees/>
        </TabPanel
      >
    <TabPanel :header="$t('compte.users')" :disabled=!admin :pt="{
      header: () => ({
        class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
      }),
    }">
        <Users/>
    </TabPanel>
    <TabPanel :header="$t('compte.contracts')" :pt="{
      header: () => ({
        class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
      }),
    }">
      <Contrats/>
    </TabPanel>
    <TabPanel :header="$t('compte.licences')" :pt="{
      header: () => ({
        class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
      }),
    }">
      <Licences/>
    </TabPanel>
    <TabPanel :header="$t('compte.invoices')" :pt="{
      header: () => ({
        class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
      }),
    }">
        <Facture/>
    </TabPanel>
    <TabPanel v-if="phlMobileContracts" :header="$t('compte.phlDoc')" :pt="{
      header: () => ({
        class: 'border-left-1 border-top-1 border-right-1 border-gray-300'
      }),
    }">
        <PHLMobile/>
    </TabPanel>
  </TabView>
    </div>
  </div>
  </div>
</template>
<script>
import Users from '@/components/Users.vue';
import Facture from '@/components/Facture.vue';
import Contrats from '@/components/Contrats.vue';
import Coordonnees from '@/components/Coordonnees.vue';
import Licences from '@/components/Licences.vue';
import PHLMobile from '@/components/DocPHLMobile.vue';

export default {
  //on charge les composants nécessaires pour la vue compte du site
    components: {
        Facture,
        Users,
        Contrats,
        Coordonnees,
        Licences,
        PHLMobile
  },
    data() {
      return {
        //on récupère l'identifiant du tabPanel qui doit être actif à l'arrivée sur la page
        active: parseInt(this.$route.params.id),
        admin: false,
        phlMobileContracts: false
      }
    },
    mounted() {
      //on vérifie s'il est admistrateur pour bloquer ou non l'accès à Utilisateurs
      if(localStorage.getItem('admin') !== undefined && localStorage.getItem("admin") !== null && localStorage.getItem("admin") !== ""){
        this.admin = localStorage.getItem("admin") === "true";
      }
      this.phlMobileContracts = localStorage.getItem("phlMobileContracts");
    },
};
</script>