<script setup>
const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

const getDateFormat = (date) => {
  date = new Date(date * 1000);
  if(date.getYear() < 2200){
    return (
      date.toLocaleDateString(undefined, options)
    );
  }
};

</script>

<template>
  <div class="card">
    <DataTable :value="licences" paginator :rows="5" sortField="date_debut" :sortOrder="-1" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem"
      :pt="{
        header: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        }),
        root: () => ({
          class: 'border-2 border-gray-300 border-top-none'
        }),
      }">
      <Column field="numContrat" :header='$t("licences.contractNumber")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column :header='$t("licences.productName")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"><template #body="slotProps">
          {{ tableau[slotProps.data.codeProduit] !== undefined ? tableau[slotProps.data.codeProduit]["label"] : slotProps.data.codeProduit }}
        </template></Column>
      <Column field="groupe_processeur" :header='$t("licences.processeurGroup")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column field="cle" :header='$t("licences.key")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column field="num_serie" :header='$t("licences.serialName")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column field="part" :header='$t("licences.partition")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column field="description" :header='$t("licences.description")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column field="date_debut" :header='$t("licences.creationDate")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }">
        <template #body="slotProps">
          {{ getDateFormat(slotProps.data.date_debut) === '01/01/1970' ? 'Non défini' : getDateFormat(slotProps.data.date_debut) }}
        </template>
      </Column>
      <Column field="" :header='$t("licences.expirationDate")' :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }">
        <template #body="slotProps">
          {{ getDateFormat(slotProps.data.date_fin) ? getDateFormat(slotProps.data.date_fin) : 'Permanent'}}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      licences: null,
      idTiers: null,
      libelles: null
    };
  },
  mounted() {
    // s'il y a un tiers payeur on prend son identifiant pour les licences 
    if(localStorage.getItem('idTiersPayeur') !== 'undefined'){
      this.idTiers = localStorage.getItem('idTiersPayeur');
    }
    else{
      this.idTiers = localStorage.getItem("idTiers");
    }
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    this.tableau = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    axios
    .post(process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/licences", {
      idTiers: this.idTiers,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer "+this.token,
      }
    })
    .then((response) => {
      //Permet d'éviter un vue warn qui préfère un tableau vide à la place d'un string vide 
        if (response['data'] !== undefined && response["data"] !== "") {
          this.licences = response["data"]["licences"];
          this.libelles = response["data"]["libelles"];   
        }
        else {
          this.licences = [];
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                life: 3000,
            });
        }
      });
  },
};
</script>