<template>
  <div>
    <Nav />
    <div class="h-auto min-h-screen flex gap-4">
      <SideBarre />
      <div class="w-10">
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import SideBarre from "@/components/SideBar.vue";
import Nav from "@/components/TopBar.vue";
import Accueil from "@/components/Accueil.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Nav,
    SideBarre,
    Footer,
    Accueil,
  },
};
</script> 
