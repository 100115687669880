<template>
    <div class="card border-round-bottom-2xl border-2 border-gray-300 border-top-none p-5">
    <div class="md:col-4">
    <p>Pour accéder à la documentation PHL Mobile : </p>

    <p>Se connecter sur : <a :href="lienPhlMobileDoc">{{ lienPhlMobileDoc }}</a></p>

    <p>Renseigner le mot de passe : {{ motDePasse }}</p>
    </div></div>
</template>
<script>
export default{
    data() {
        return {
            motDePasse: null,
            lienPhlMobileDoc: null
        }
    },
    mounted(){
        this.motDePasse = process.env.VUE_APP_MDP_PHL_MOBILE;
        this.lienPhlMobileDoc = process.env.VUE_APP_LIEN_PHL_MOBILE;
    }
}
</script>