<script setup>
import axios from "axios";
import { useToast } from "primevue/usetoast";

const toast = useToast();

</script>
<template>
  <div class="card">
    <DataView :value="personnes" :layout="layout" :pt="{
      root: () => ({
        class:
          'border-2 border-gray-300 border-top-none',
      }),
      header: () => ({
        class: 'border-none bg-white'
      })
    }">
      <template #header >
        <div class="flex justify-content-between">
            <Button :label="$t('users.addUser')" icon="pi pi-user-plus" class="border-round-xl h-2rem" @click="addUser()"/>
        </div>
      </template>
      <template #grid="slotProps">
        <div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
          <div class="p-4 border-1 surface-border surface-card border-round">
            <div class="flex flex-column align-items-center gap-3 py-5">
              <div class="text-2xl font-bold">
                {{ slotProps.data.firstname }} {{ slotProps.data.lastname }}
              </div>
              <p>{{ slotProps.data.poste }}</p>
              <p v-if="slotProps.data.phone_pro !== ''" class="m-0">
                {{ $t("users.phone") }} : {{ slotProps.data.phone_pro }}
              </p>
              <p v-else class="m-0">{{ $t("users.phone") }} : {{ $t("users.undefined") }}</p>
              <p v-if="slotProps.data.email !== ''" class="m-0">
                {{ $t("users.mail") }} : {{ slotProps.data.email }}
              </p>
              <p v-else class="m-0">{{ $t("users.mail") }} : {{ $t("users.undefined") }}</p>
            </div>
            <div class="flex align-items-center justify-content-between">
              <div>
                <Button icon="pi pi-user-edit" rounded :label="$t('users.modify')"
                  @click="modifUser(slotProps.data.id, slotProps.data.email)" text class="text-700" :pt="{
                    root: () => ({
                      class: 'hover:surface-300 hover:text-900'
                    })
                  }"/>
                <Button icon="pi pi-user-edit" rounded :label="$t('modifMdp.resetPassword')"
                  @click="resetPassword(slotProps.data.email)" text class="text-700" :pt="{
                    root: () => ({
                      class: 'hover:surface-300 hover:text-900'
                    })
                  }"/>
              </div>
              <Button icon="pi pi-times" rounded :label="$t('users.delete')"
                @click="displayModal(slotProps.data.id, slotProps.data.email)" text class="text-700" :pt="{
                    root: () => ({
                      class: 'hover:surface-300 hover:text-900'
                    })
                  }"/>
            </div>
          </div>
        </div>
        <Dialog v-model:visible="displayModalDelete" modal :header="$t('users.contactDeletion')" :style="{ width: '30vw' }">
          <div class="text-center">
            <p>{{ $t('users.confirmDeletion') }} {{ mailContactDelete }} ?</p>
            <Button :label="$t('users.confirm')" icon="pi pi-check" class="bg-green-300 border-green-300 mr-3" @click="deleteUser()"/>
            <Button :label="$t('comment.cancel')" icon="pi pi-times" class="bg-red-300 border-red-300" @click="displayModalDelete = false"/>
          </div>
        </Dialog>
      </template>
    </DataView>
  </div>
  <Toast />
</template>
<script>
export default {
  data() {
    return {
      personnes: null,
      idTiers: "",
      layout: "grid",
      displayModalDelete: false,
      mailContactDelete: "",
      idContactDelete: null
    };
  },
  mounted() {
    this.idTiers = localStorage.getItem("idTiers");
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    this.mailClient = localStorage.getItem('mail');
    this.admin = localStorage.getItem('admin');
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/contacts",
        {
          idTiers: this.idTiers,
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token,
          },
        }
      )
      .then((response) => {
        this.personnes = response["data"];
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] !== undefined && e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      });
  },
  methods: {
    addUser() {
      this.$router.push("/creationUtilisateur");
    },
    modifUser(id, mail) {
      localStorage.setItem("contactModifMail", mail);
      localStorage.setItem("contactModifIdDolibarr", id);
      this.$router.push("/modifUtilisateur");
    },
    displayModal(id, mail){
      this.idContactDelete = id;
      this.mailContactDelete = mail;
      this.displayModalDelete = true;
    },
    deleteUser() {
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/supprContact",
          {
            idDolibarr: this.idContactDelete,
            mail: this.mailContactDelete,
            admin: this.admin
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+this.token,
            },
          }
        )
        .then((response) => {
          if(mail === this.mailClient){
            this.$router.push('/login');
          }
          else{
            location.reload();
          }
        })
        .catch((e) => {
          //Token invalide ou expiré
          if(e['response'] !== undefined && e['response']['status'] !== undefined && e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
          }
        });
    },
    resetPassword(mail){
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/sendMailResetPassword",
          {
            email: mail,
            admin: this.admin
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$toast.add({
            severity: "info",
            summary: this.$t("passwordForgot.mailSent"),
            detail: this.$t("passwordForgot.mailSentDetail"),
            life: 10000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("erreur.erreur"),
            detail:  this.$t("erreur.erreurInterne"),
            life: 3000,
          });
        });
    }
  },
};
</script>