<script setup>
import { useToast } from "primevue/usetoast";
import axios from "axios";

const toast = useToast();

</script>
<template>
  <div class="flex h-screen w-full">
    <div class="hidden md:block bg-no-repeat bg-cover logo-design border-gray-600 border-right-1 w-6"></div>
    <div class="flex flex-column surface-section justify-content-center">
      <div class="flex align-items-center justify-content-center">
        <div
          class="flex w-full h-30rem border-round-2xl border-gray-500 border-2 md:w-6 p-2 md:p-4 flex-column justify-content-around">
          <div class="mb-3 flex justify-content-center gap-5">
            <h1 class="text-900 font-medium mb-3">{{ $t('connexion.connectionTitle') }}</h1>
          </div>
          <div>
            <div class="pb-3">
            <span class="p-float-label">
              <InputText type="text" v-model="username" :class="['w-full p-2 poppins', { 'p-invalid': errorUsername }]" />
              <label style="font-style: italic">{{ $t('connexion.login') }}</label>
            </span>
            <small class="p-error">{{
              errorUsername ? $t("connexion.requiredLogin") : "&nbsp;"
            }}</small>
            </div>
            <div class="pb-3">
            <span class="p-float-label">
              <InputText @keypress.enter="enter()" v-model="password" type="password" :feedback="false"
                :class="['w-full p-2 poppins', { 'p-invalid': errorPassword }]" />
              <label style="font-style: italic">{{ $t('connexion.password') }}</label>
            </span>
            <small class="p-error">{{
              errorPassword ? $t("connexion.requiredPassword") : "&nbsp;"
            }}</small>
            </div>
            <div class="flex flex-column align-items-center h-9rem w-full justify-content-between">
              <Button id="connect" type="button" :loading="loading" @click="connexion()" rounded :label="$t('connexion.connectionButton')"/>
              <Button class="poppins" link @click="forgotPassword()">{{ $t('connexion.forgotPassword') }}</Button>
              <i class="text-center">{{ $t('connexion.connectionIssue', { emailSupport: 'support@phlsoft.com' })  }}</i>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="pt-7 text-center">
          PHL Soft {{ new Date().getFullYear() }} | {{ $t("connexion.ArmonieBrand") }}
        </p>
      </div>
    </div>
  </div>
  <Toast />
</template >
<script>

export default {
  data() {
    return {
      json: null,
      checkbox: false,
      errorPassword: false,
      errorUsername: false,
      errorAuth: false,
      errorMail: false,
      username: "",
      password: "",
      mail: "",
      textError: "",
      infosDolibarr: "",
      tiers: "",
      loading: false
    };
  },
  methods: {
    //capture de la touche entrer pour la connexion
    enter() {
      let btn = document.getElementById("connect");
      btn.click();
    },
    connexion() {
      //on vérifie que les champs ne sont pas vides
      this.loading = true;
      this.errorPassword = this.password === "";
      this.errorUsername = this.username === "";
      let emailValid = this.username.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
      if (!this.errorPassword && !this.errorUsername && (emailValid || this.username.includes('phl'))) {
        //on check la connexion coté back-end
        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/auth",
            {
              mail: this.username,
              password: this.password,
            },
            {
              headers: {
                //on envoie le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.errorAuth = response.status !== 200;
            if (!this.errorAuth) {
              //on initialise des variables dans le localstorage pour éviter de refaire des requêtes à chaque changement de page
              localStorage.setItem("idDolibarr", response["data"]["userData"][0]["id"]);
              localStorage.setItem(
                "nom",
                response["data"]["userData"][0]["lastname"]
              );
              localStorage.setItem(
                "prenom",
                response["data"]["userData"][0]["firstname"]
              );
              localStorage.setItem(
                "idTiers",
                response["data"]["userData"][0]["socid"]
              );
              localStorage.setItem(
                "mail",
                response["data"]["userData"][0]["email"]
              ); localStorage.setItem(
                "tel",
                response["data"]["userData"][0]["phone_pro"]
              );
              localStorage.setItem(
                "idTiersPayeur",
                response["data"]["userData"][0]["socid_payeur"]
              );
              localStorage.setItem("nomEntreprise", response["data"]["userData"][0]["socname"]);
              //on récupère le token 
              localStorage.setItem("token", response["data"]["token"]);
              localStorage.setItem("tokenExpiration", response["data"]["tokenExpiration"]);
              //on récupère le fait qu'il soit admin
              localStorage.setItem("admin", response["data"]["role"]);
              localStorage.setItem("idBDD", response["data"]["idBDD"]);
              //et on le connecte
              localStorage.setItem("connecte", true);

              // On vérifie si l'utilisateur à le mail avec @armonie.group et appartient à la bonne entreprise
              let emailAdmin = this.username.includes('@armonie.group');
              let isArmonie = localStorage.getItem("idTiers") === '18500';
              if(emailAdmin && isArmonie){
                localStorage.setItem('adminPhl', emailAdmin && isArmonie);
                this.infosDolibarr = "PHL SOFT : " + localStorage.getItem("prenom") + " " + localStorage.getItem("nom");
              }
              else{ 
                //pour l'affichage dans les tickets pour connaitre la personne qui l'a envoyé
                if(localStorage.getItem("tel") === ''){
                  this.infosDolibarr = localStorage.getItem("nomEntreprise") + ' : ' + localStorage.getItem("prenom") + " " + localStorage.getItem("nom");
                }
                else{
                  this.infosDolibarr = localStorage.getItem("nomEntreprise") + ' : ' + localStorage.getItem("prenom") + " " + localStorage.getItem("nom") + " - " + localStorage.getItem("tel");
                }
              }

              localStorage.setItem("infosDolibarr", this.infosDolibarr);
              if(response["data"]["byPass"] != false){
                localStorage.setItem("roleBypass", response["data"]["byPass"]);
              }

              //récupération du token backend dans le local storage
              this.token = localStorage.getItem("token");

              //on récupère la liste des libellés de Dolibarr
              axios
                .get(
                  process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/libelles",
                  {
                    headers: {
                      //on envoie le token d'authentification et le format des données envoyés en POST
                      "Content-Type": "multipart/form-data",
                      "Authorization": "Bearer "+this.token,
                    },
                  }
                )
                .then((res) => {
                  localStorage.setItem("libellesProduitsPHL", JSON.stringify(res["data"]));
                  if(localStorage.getItem("roleBypass") == null){
                    // s'il y a un tiers payeur on prend son identifiant pour les factures impayées
                    if(localStorage.getItem('idTiersPayeur') !== 'undefined'){
                      this.tiers = localStorage.getItem('idTiersPayeur');
                    }
                    else{
                      this.tiers = localStorage.getItem('idTiers');
                    }
                    //on vérifie que le client n'a pas de factures impayées
                    axios
                    .post(
                        process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/facturesImpayees",
                        {
                          idTiers: this.tiers,
                        },
                        {
                          headers: {
                            //on envoie le token d'authentification et le format des données envoyés en POST
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer "+this.token,
                          },
                        }
                      )
                      .then((res) => {
                        //code 204 : no content -> il n'a pas de factures impayées donc il a accès à l'accueil
                        if (res.status === 204) {
                          localStorage.setItem("factureImpayee", false);
                          this.$router.push("/accueil");
                        }
                        //il a des factures impayées donc il est bloqué sur la page des factures impayées 
                        else if (res.status === 200) {
                          localStorage.setItem("factureImpayee", true);
                          this.$router.push("/factureImpayee");
                        }
                      }).catch((e) => {
                        //Token invalide ou expiré
                        if(e['response']['status'] === 401){
                          this.$router.push("/login");
                        }
                        //gestion d'erreur pour la requête des factures impayées
                        else{
                          this.$toast.add({
                            severity: "error",
                            summary: this.$t('erreur.erreur'),
                            detail: this.$t('erreur.erreurInterne'),
                            life: 3000,
                          });
                          this.loading = false;
                        }
                      })
                    }
                  // Si le rôle de bypass est présent alors on ne vérifie pas les factures
                  else{
                      localStorage.setItem("factureImpayee", false);
                      this.$router.push("/accueil");
                  }   
                }).catch((e) => {
                  //Token invalide ou expiré
                  if(e['response']['status'] === 401){
                    this.$router.push("/login");
                  }
                  //gestion d'erreur pour les libellés PHL
                  else{
                    this.$toast.add({
                      severity: "error",
                      summary: this.$t('erreur.erreur'),
                      detail: this.$t('erreur.erreurInterne'),
                      life: 3000,
                    });
                    this.loading = false;
                  }
                })
            }
          })
          .catch((e) => {
            //gestion d'erreur pour la requête d'authentification
            if(e['response']['data']['code'] === 405){
              this.$toast.add({
                severity: "warn",
                summary: this.$t('connexion.warning'),
                detail: this.$t("connexion.warnMailOrPassword"),
                life: 3000,
              });
            }
            else if(e['response']['data']['code'] !== undefined){
              this.$toast.add({
                severity: "error",
                summary: this.$t("connexion.connectionFailed"),
                detail: "Code: " + e['response']['data']['code'],
                life: 3000,
              });
            }
            else{
              this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
              });
            }
            this.loading = false;
          })
      }
      else if(!emailValid && !this.username.includes('phl')){
        this.$toast.add({
          severity: "warn",
          summary: this.$t('connexion.warning'),
          detail: this.$t('connexion.notValidEmail'),
          life: 3000,
        });
        this.loading = false;
      }
      else this.loading = false;
    },
    forgotPassword() {
      this.$router.push('/passwordForgot');
    }
  },
};
</script>
<style>
.logo-design {
  background-image: url("../assets/MicrosoftTeams-image.png");
}
</style>
