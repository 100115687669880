<script setup>
import { useToast } from "primevue/usetoast";
import axios from "axios";
const toast = useToast();
</script>

<template>
  <Nav />
  <div class="card">
    <div class="flex justify-content-center card-container">
      <div
        class="flex w-full h-25rem md:w-5 p-3 md:p-4 flex-column ">
        <div class="flex justify-content-center text-center">
          <i>{{ $t('resetPassword.info') }} : </i>
        </div>
        <div class="pt-5">
          <span class="p-float-label">
            <InputText v-model="password" type="password"
              :class="['w-full p-2 poppins', { 'p-invalid': errorPassword }]" />
            <label style="font-style: italic">{{ $t('resetPassword.password') }}</label>
          </span>
          <small class="p-error">{{
            errorPassword ? $t("resetPassword.requiredPassword") : "&nbsp;"
          }}</small>
          <span class="p-float-label">
            <InputText v-model="confirmPassword" type="password"
              :class="['w-full p-2 poppins', { 'p-invalid': errorConfirmPassword }]" />
            <label style="font-style: italic">{{ $t('resetPassword.confirmPassword') }}</label>
          </span>
          <small class="p-error">{{
            errorConfirmPassword ? $t("resetPassword.requiredConfirmPassword") : "&nbsp;"
          }}</small>
          <Message v-if="passwordSuccesfullyUpdate" severity="success">{{ $t('resetPassword.redirection') }} <a href="/">{{ $t('resetPassword.connexion') }}</a></Message>
          <Message v-if="passwordUpdateError" severity="error">{{ $t('resetPassword.erreur') }}</Message>
          <div class="flex flex-column align-items-center h-9rem w-full justify-content-between">
            <Button id="resetPassword" type="submit" rounded @click="resetPassword()">{{ $t('resetPassword.confirmNewPassword')
            }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/TopBar.vue';
export default {
  components: {
    Nav
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorPassword: false,
      errorConfirmPassword: false,
      passwordSuccesfullyUpdate: false,
      passwordUpdateError: false
    }
  },
  methods: {
    resetPassword() {
      this.errorPassword = this.password === "";
      this.errorConfirmPassword = this.confirmPassword === "";
      if (!this.errorPassword && !this.errorConfirmPassword) {
        if(this.password === this.confirmPassword){
          axios
            .post(
              process.env.VUE_APP_URL_BACK_END + "/resetPassword",
              {
                email: this.$route.query.email,
                token: this.$route.query.token,
                mdp1: this.password,
                mdp2: this.confirmPassword
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              this.passwordSuccesfullyUpdate = true;
            })
            .catch(() => {
              this.passwordUpdateError = true;
            });
        } else {
          this.$toast.add(
            {
              severity: "warn",
              summary: "Attention",
              detail: "Les 2 nouveaux mots de passe ne correspondent pas.",
              life: 3000,
            }
          );
        }
      }
    }
  }
};
</script>