import { createRouter, createWebHistory } from "vue-router";
//Composants
import TicketVue from "@/components/Tickets.vue";
import OpenTicket from "@/components/CreateTicket.vue";
import TicketHistory from "@/components/TicketHistory.vue";
import Accueil from "@/components/Accueil.vue";
import Compte from "@/components/Compte.vue";
import Comment from "@/components/Comment.vue";
import PageNotFound from "@/components/404.vue";
import User from '@/components/CreateUser.vue';
import ModifUser from '@/components/ModifMdp.vue';
import Forum from '@/components/Forum.vue';
import CreateChat from '@/components/CreateChat.vue';
import FichesTechniques from '@/components/FichesTechs.vue';
import Telechargements from '@/components/Telechargements.vue';
import FormChangementMachine from '@/components/FormChangementMachine.vue';
import SelectionTiers from "@/components/SelectionTiers.vue";
import ModifMail from "@/components/ModifMail.vue";
import ModifInfos from "@/components/ModifInfos.vue";

//Vues
import Login from "@/views/Login.vue";
import MainView from "@/views/MainView.vue";
import factureImpayee from "@/views/FactureImpayee.vue";
import passwordForgot from "@/views/passwordForgot.vue";
import resetPassword from "@/views/resetPassword.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/factureImpayee",
    name: "factureImpayee",
    component: factureImpayee,
  },
  {
    path: "",
    name: "mainView",
    component: MainView,
    children: [
      {
        path: "accueil",
        name: "accueil",
        component: Accueil,
      },
      {
        path: "ouvrirTicket",
        name: "ouvrirTicket",
        component: OpenTicket,
      },
      {
        path: "mesTickets",
        name: "mesTickets",
        component: TicketVue,
      },
      {
        path: "ticket/:idTicket",
        name: "ticket",
        component: TicketHistory,
      },
      {
        path: "compte/:id",
        name: "compte",
        component: Compte,
      },
      {
        path: "comment/:idTicket",
        name: "comment",
        component: Comment,
      },
      {
        path: "creationUtilisateur",
        name: "creationUtilisateur",
        component: User,
      },
      {
        path: "modifUtilisateur",
        name: "modifUtilisateur",
        component: ModifUser,
      },
      {
        path: "modifMail",
        name: "modifMail",
        component: ModifMail,
      },
      {
        path: "modifInfos",
        name: "modifInfos",
        component: ModifInfos,
      },
      {
        path: "forum",
        name: "/forum",
        component: Forum,
      },
      {
        path: "ouvrirDiscussion",
        name: "/ouvrirDiscussion",
        component: CreateChat,
      },
      // {
      //   path: "smartClub",
      //   name: "/smartClub",
      //   component: SmartClub,
      // },
      {
        path: "fichesTechniques",
        name: "/fichesTechniques",
        component: FichesTechniques,
      },
      {
        path: "telechargements",
        name: "/telechargements",
        component: Telechargements,
      },
      {
        path: "formulaireChangementMachine",
        name: "/formulaireChangementMachine",
        component: FormChangementMachine,
      },
      {
        path: "selectionTiers",
        name: "/selectionTiers",
        component: SelectionTiers,
      },
    ],
  },
  {
    path: "/passwordForgot",
    name: "passwordForgot",
    component: passwordForgot,
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: resetPassword,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  //Pas de vérifications spéficique pour ces routes
  if(to.name !== 'passwordForgot' && to.name !== 'resetPassword'){
    
    //vérifier s'il est authentifié 
    let isAuthenticated = localStorage.getItem('token') !== undefined && localStorage.getItem("token") !== null;
    let admin = localStorage.getItem('admin') !== undefined && localStorage.getItem("admin") !== null && localStorage.getItem("admin") === "true";
    let isAdminPhl = localStorage.getItem('adminPhl') !== undefined && localStorage.getItem("adminPhl") !== null && localStorage.getItem("adminPhl") === "true";
    let factureImpayee = localStorage.getItem("factureImpayee") !== undefined && localStorage.getItem("factureImpayee") !== null && localStorage.getItem("factureImpayee") !== "" && localStorage.getItem("factureImpayee") === "true";
    let dateToken = localStorage.getItem("tokenExpiration");
    //on vérifie qu'on est pas sur la page de login et que l'utilisateur est authentifié
    if (!isAuthenticated && to.name !== 'login') {
      router.push('/login');
    }
    //on vérifie que ce n'est pas un mauvais payeur
    if (factureImpayee && to.name !== 'factureImpayee') {
      router.push('/factureImpayee');
    }
    //on vérifie (pour la page référencant les utilisateurs) qu'il a le droit admin
    if (to.name === 'compte' && to.params.id === "1") {
      if (!admin) {
        router.push('/compte/0');
      }
    }
    //on vérifie qu'il a les droits administrateurs pour la page de création des utilisateurs
    if(to.name === 'creationUtilisateur' && !admin){
      router.push('/compte/0');
    }
    //pour gérer la déconnexion qui ne passe pas par le bouton
    if(to.name === 'login' && isAuthenticated){
      localStorage.clear();
    }
    //on vérifie la date d'expiration du token
    if(to.name !== 'login' && dateToken < Math.floor(Date.now() / 1000)){
      router.push('/login');
    }
    //On vérifie que pour l'accès à la sélection du tiers c'est un admin PHL
    if(!isAdminPhl && to.name === '/selectionTiers'){
      router.push('/accueil');
    }
  }
})

export default router;