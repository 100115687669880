<template>
    <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-900 text-4xl font-normal border-round-top-2xl p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700">
      {{ $t('contrats.amendmentRequest') }} {{ nomContrat }}
    </div>
    <div class="surface-card p-4 border-round-bottom-2xl border-2 border-500">
        <div class="flex flex-column p-fluid">
            <div class="flex align-items-center">
                <label for="radio" class="font-medium text-900 w-3">{{ $t('formChangementMachine.concernedProducts') }}</label>
                <div class="flex flex-wrap gap-3" id="radio">
                    <div class="flex align-items-center">
                        <RadioButton v-model="type" inputId="inf" name="version" value="inf" @click="this.supForm = false;this.infForm = true;this.erreurVersion = false;" :class="{ 'p-invalid': erreurVersion }"/>
                        <label for="inf" class="ml-2">{{ $t('formChangementMachine.lessThan') }} 6.4</label>
                    </div>
                    <div class="flex align-items-center">
                        <RadioButton v-model="type" inputId="sup" name="version" value="sup" @click="this.infForm = false;this.supForm = true;this.erreurVersion = false;" :class="{ 'p-invalid': erreurVersion }"/>
                        <label for="sup" class="ml-2">6.4 {{ $t('formChangementMachine.andHigher') }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="infForm">
            <div class="flex align-items-center">
                <label class="font-medium text-900 w-5">{{ $t('licences.serialName') }} {{ $t('formChangementMachine.ofMainMachine') }}</label>
                <InputText type="text" v-model="numPrinc" :class="['w-full md:w-14rem', { 'p-invalid': erreurMachinePrincipale }]" />
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div class="flex align-items-center">
                <label class="font-medium text-900 w-5">{{ $t('licences.serialName') }} {{ $t('formChangementMachine.ofBackupMachine') }}</label>
                <InputText type="text" v-model="numBackup" :class="['w-full md:w-14rem']" />
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
        </div>
        <div v-if="supForm">
            <p class="text-red-500">{{ $t('formChangementMachine.warningRevamp') }}</p>
            <div class="flex align-items-center">
                <label class="font-medium text-900 w-5"></label>
                <label class="w-full md:w-14rem mr-2">{{ $t('licences.serialName') }}</label>
                <label class="w-full md:w-14rem mr-2">{{ $t('licences.processeurGroup') }}</label>
                <label class="w-full md:w-14rem mr-2">N° {{ $t('licences.partition') }}</label>
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div class="flex align-items-center">
                <label for="sujet" class="font-medium text-900 w-5">{{ $t('formChangementMachine.devMachine') }}</label>
                <InputText type="text" v-model="numDev" :class="['w-full md:w-14rem mr-2', { 'p-invalid': erreurNumDev }]" maxlength="7"/>
                <Dropdown v-model="selectedProcDev" :options="proc" optionLabel="name" name="procDev"
                    :class="['w-full md:w-14rem mr-2', { 'p-invalid': erreurProcDev }]" />
                <InputNumber :useGrouping="false" v-model="numPartDev" :class="['w-full md:w-14rem mr-2', { 'p-invalid': erreurNumPartDev }]" />
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div class="flex align-items-center">
                <label for="sujet" class="font-medium text-900 w-5">{{ $t('formChangementMachine.prodMachine') }}</label>
                <InputText type="text" v-model="numProd" :class="['w-full md:w-14rem mr-2']" maxlength="7"/>
                <Dropdown v-model="selectedProcProd" :options="proc" optionLabel="name" name="procProd"
                    :class="['w-full md:w-14rem mr-2']" />
                <InputNumber :useGrouping="false" v-model="numPartProd" :class="['w-full md:w-14rem mr-2']" />
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div class="flex align-items-center">
                <label for="sujet" class="font-medium text-900 w-5">{{ $t('formChangementMachine.backupMachine') }}</label>
                <InputText type="text" v-model="numBack" :class="['w-full md:w-14rem mr-2']" maxlength="7"/>
                <Dropdown v-model="selectedProcBack" :options="proc" optionLabel="name" name="procBack"
                    :class="['w-full md:w-14rem mr-2']" />
                <InputNumber :useGrouping="false" v-model="numPartBack" :class="['w-full md:w-14rem mr-2']" />
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div class="flex align-items-center">
                <label class="font-medium text-900 w-5"></label>
                <label class="w-full md:w-14rem mr-2">{{ $t('licences.serialName') }}</label>
                <label class="w-full md:w-14rem mr-2">{{ $t('licences.processeurGroup') }}</label>
                <label class="w-full md:w-14rem mr-2">N° {{ $t('licences.partition') }}</label>
            </div>
            <div class="flex flex-column mb-3 w-12"></div>
            <div v-for="(instance, index) in machinesRuntime" :key="index">
                <div class="flex align-items-center">
                        <label for="sujet" class="font-medium text-900 w-5">Runtime {{ index + 1 }}</label>
                        <InputText type="text" v-model="instance.numSerie" :class="['w-full md:w-14rem mr-2']" :name="'numSerie_' + (index + 1)" maxlength="7"/>
                        <Dropdown v-model="instance.selectedProc" :options="proc" optionLabel="name" name="proc" :class="['w-full md:w-14rem mr-2']" />
                        <InputNumber :useGrouping="false" v-model="instance.numPart" :class="['w-full md:w-14rem mr-2']" :name="'numPart_' + (index + 1)"/>
                    </div>
                    <div class="flex flex-column mb-3 w-12"></div>
                </div>
                <Button @click="ajouterRuntime" class="border-round-2xl bg-indigo-500 border-indigo-500" icon="pi pi-plus" label="Ajouter une nouvelle machine" />
            </div>

      <div class="flex justify-content-center">
        <Button type="submit" id="click" class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3" @click="onSubmit()">{{ $t("comment.validate") }}</Button>
        <router-link :to="'/compte/2'" class="no-underline">
            <Button class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">{{ $t("comment.cancel") }}</Button>
        </router-link>
      </div>
      </div></div>
  <Toast />
  <Dialog v-model:visible="displayModalPdf" modal :header="'Avenant ' + nomContrat" :style="{ width: '70vw' }">
    <p class="text-red-500 text-xl" v-html="annonce"></p>
    <iframe :src="pdfData" :title="'Avenant contrat n°' + nomContrat" width='100%' style="height:60vh;"></iframe>
  </Dialog>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            type: null,
            proc: [
                {name: "P05"}, 
                {name: "P10"}, 
                {name: "P20"}, 
                {name: "P30"}, 
                {name: "P40"}, 
                {name: "P50"}, 
            ],
            numDev: "",
            numProd: "",
            numBack: "",
            numPartDev: 0,
            numPartProd: 0,
            numPartBack: 0,
            numPrinc: "",
            numBackup: "",
            selectedProcBack: "",
            selectedProcDev: "",
            selectedProcProd: "",
            machinesRuntime: [
                { 
                    numSerie: '', 
                    selectedProc: null, 
                    numPart: 0
                }
            ],

            infForm: false,
            supForm: false,
            erreurVersion: false,
            erreurMachinePrincipale: false,
            erreurNumDev: false,
            erreurProcDev: false,
            erreurNumPartDev: false,
            annonce: this.$t("formChangementMachine.downloadPDF"),
            pdfData: "",
            displayModalPdf: false,
            nomContrat: localStorage.getItem("refContrat")
        }
    },
    methods: {
        ajouterRuntime() {
            this.machinesRuntime.push(
                { 
                    numSerie: '', 
                    selectedProc: null, 
                    numPart: 0
                }
            );
        },
        onSubmit() {
            // Cas ou la version n'a pas été sélectionnée 
            if(this.type == null){
                this.erreurVersion = true;
                this.$toast.add({
                    severity: "warn",
                    summary: this.$t('erreur.erreur'),
                    detail: "Une version est nécessaire",
                    life: 3000,
                });
            }
            else{

                let body = null;
                
                // Cas pour une version inférieure à la 6.4 
                if(this.type === "inf"){
                    if(this.numPrinc == "" || this.numPrinc == undefined){
                        this.erreurMachinePrincipale = true;
                        this.$toast.add({
                            severity: "warn",
                            summary: this.$t('erreur.erreur'),
                            detail: "Une machine est nécessaire",
                            life: 3000,
                        });
                    }
                    else{
                        let machinePrincipale = {
                            numSerie: this.numPrinc
                        }
                        let machineBackup = {
                            numSerie: this.numBackup
                        }
                        // Ensemble de données pour la requête API
                        body = {
                            "version": "inf",
                            "refContrat": this.nomContrat,
                            "entreprise": localStorage.getItem('nomEntreprise'),
                            "machineProd": machinePrincipale,
                            "machineBackup": machineBackup,
                            "produits": localStorage.getItem('produits')
                        };
                    }
                }
                // Cas pour une version supérieure à la 6.4 
                else{
                    // this.erreurNumDev = false;
                    // this.erreurProcDev = false;
                    // this.erreurNumPartDev = false;
                    // let machineDeveloppementNonRenseignee = 
                    //     this.numDev == "" || this.numDev == undefined ||
                    //     this.selectedProcDev == "" ||
                    //     this.numPartDev == "" || this.numPartDev == undefined;
                    // if(machineDeveloppementNonRenseignee){
                    //     if (this.numDev == "" || this.numDev == undefined) {
                    //         this.erreurNumDev = true;
                    //     }
                    //     if (this.selectedProcDev == "" || this.selectedProcDev == undefined) {
                    //         this.erreurProcDev = true;
                    //     }
                    //     if (this.numPartDev == "" || this.numPartDev == undefined) {
                    //         this.erreurNumPartDev = true;
                    //     }
                    //     this.$toast.add({
                    //         severity: "warn",
                    //         summary: this.$t('erreur.erreur'),
                    //         detail: "Une machine est nécessaire",
                    //         life: 3000,
                    //     });
                    // }
                    // else{
                        // Récupération des données du formulaire 
                        let listeMachinesRuntime = this.machinesRuntime.map(instance => {
                            return {
                                numSerie: instance.numSerie,
                                selectedProc: instance.selectedProc != null ? instance.selectedProc["name"] : "",
                                numPart: instance.numPart,
                            };
                        });
                        // Données de la machine de développement
                        let machineDev = {
                            numSerie: this.numDev,
                            selectedProc: this.selectedProcDev["name"] != undefined ? this.selectedProcDev["name"] : "",
                            numPart: this.numPartDev,
                        }
                        // Données de la machine de production 
                        let machineProd = {
                            numSerie: this.numProd,
                            selectedProc: this.selectedProcProd["name"] != undefined ? this.selectedProcProd["name"] : "",
                            numPart: this.numPartProd,
                        };
                        // Données de la machine de backup
                        let machineBack = {
                            numSerie: this.numBack,
                            selectedProc: this.selectedProcBack["name"] != undefined ? this.selectedProcBack["name"] : "",
                            numPart: this.numPartBack,
                        };
                        // Ensemble de données pour la requête API
                        body = {
                            "version": "sup",
                            "refContrat": this.nomContrat,
                            "entreprise": localStorage.getItem('nomEntreprise'),
                            "machinesRuntime": listeMachinesRuntime,
                            "machineProd": machineProd,
                            "machineBackup": machineBack,
                            "machineDev": machineDev,
                            "produits": localStorage.getItem('produits')
                        };
                    // }
                }
                if(body != null){
                    //récupération du token backend dans le local storage
                    this.token = localStorage.getItem("token");
                    
                    // Appel API pour générer le pdf
                    axios.post(
                        process.env.VUE_APP_URL_BACK_END + "/generatePdf",
                        {
                            params: body
                        },
                        {
                        headers: {
                            //on envoie le token d'authentification et le format des données envoyés en POST
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer "+this.token,
                        },
                    }
                    ).then((response) => {
                        if(response.status === 200){
                            this.pdfData = "data:application/pdf;base64," + encodeURI(response.data);
                            this.displayModalPdf = true;
                        }
                    }).catch((e) => {
                        //Token invalide ou expiré
                        if(e['response']['status'] === 401){
                            this.$router.push("/login");
                        }
                        //capture des erreurs revoyées par le back-end
                        else{
                            this.$toast.add({
                                severity: "warn",
                                summary: this.$t('erreur.erreur'),
                                detail: this.$t('erreur.erreurInterne'),
                                life: 3000,
                            });
                        }
                    });
                }
            }
        }
    },
}
</script>