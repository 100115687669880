<template>
  <NavImpayee />
  <div class="card w-full p-3 md:p-7">
    <TabView
      :pt="{
        panelContainer: () => ({
          class: 'p-0',
        }),
      }"
    >
      <TabPanel
        :header="$t('impayee.unpaidInvoicesList')"
        :pt="{
          header: () => ({
            class:
              'border-left-1 border-top-1 border-right-1 border-gray-300',
          }),
        }"
      >
        <TabFacture />
      </TabPanel>
    </TabView>
  </div>
  <Footer />
</template>
<script>
import TabFacture from "@/components/Facture.vue";
import NavImpayee from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    TabFacture,
    NavImpayee,
    Footer,
  },
};
</script>