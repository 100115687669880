<template>
  <div>
    <div class="card mb-4">
        <p class="text-4xl pr-4 pl-2 text-700">{{ $t('sideBar.techFiles') }}</p>
        <Accordion :multiple="true" :activeIndex="[0]">
          <AccordionTab v-for="(dossier, index) in dossiers" :header="index">
            <DataTable :value="dossier[0]" v-model:filters="filters" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" filterDisplay="row" :globalFilterFields="['filename']" 
            tableStyle="min-width: 50rem" :pt="{
              header: () => ({
                class: 'bg-white border-right-none border-left-none border-dashed border-2'
              }),
              root: () => ({
                class: 'border-2 border-gray-300'
              }),
            }">
            <Column field="filename" :header='$t("fichesTechniques.filename")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
          <template #filter="{ filterModel, filterCallback }">
            <div class="w-5">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" :placeholder="$t('fichesTechniques.filter')" />
            </div>
          </template>  
          </Column>
            <Column field="view" :header='$t("fichesTechniques.view")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
            <template #body="slotProps">
                <i @click="loadPdfModal(slotProps.data.filename, slotProps.data.hash, slotProps.data.id)" class="pi pi-eye cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem"></i>
              </template>  
            </Column>
            <Column field="link" :header='$t("fichesTechniques.download")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  <i class="pi pi-download cursor-pointer" @click="openFile(slotProps.data.filename, slotProps.data.hash, slotProps.data.id)"
                    style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                </div>
              </template>
            </Column>
          </DataTable>
        </AccordionTab>
      </Accordion>
    </div>

    
    <Dialog v-model:visible="displayModalPdf" modal :header="headerModalPdf" :style="{ width: '70vw' }">
      <iframe :src="pdfData" width='100%' style="height:60vh;"></iframe>
    </Dialog>


  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode } from 'primevue/api';

export default {
  data() {
    return {
      idTiers: null,
      dossiers: null,
      mail: null,
      displayModalPdf: false,
      pdfData : null,
      headerModalPdf: null,
      filters: {
          filename: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
    };
  },
  mounted() {
    this.idTiers = localStorage.getItem("idTiers");
    this.lang = this.$i18n.locale;
    this.token = localStorage.getItem('token');
    this.mail = localStorage.getItem('mail');
    //on récupère la liste des fichiers dans 
    axios
      .post(process.env.VUE_APP_URL_BACK_END + "/api/fichiers/listeTechs", {
        idTiers: this.idTiers,
        lang: this.lang,
      }, {
        headers: {
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        //Permet d'éviter un vue warn qui préfère un tableau vide à la place d'un string vide 
        if (response['data'] !== undefined && response["data"] !== "") {
          this.dossiers = response["data"];
        }
        else {
          this.dossiers = [];
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                life: 3000,
            });
        }
      });
  },
  methods: {
    // on appelle la route avec le nom du fichier et le hash corrsspondant 
    // pour éviter de donner des fichiers sensibles à des personnes n'ayant pas les accès
    openFile(nomFichier, hash, id) {
      axios
        .post(process.env.VUE_APP_URL_BACK_END + "/api/fichiers/element", {
          nomFichier: nomFichier,
          hash: hash,
          id: id,
          idTiers: this.idTiers,
          mail: this.mail
        }, {
          headers: {
            "Authorization": "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        })
        .then((response) => {
          //permet de télécharger le document en réponse de la requête
          //cf. https://bobbyhadz.com/blog/download-file-using-axios#download-a-file-using-axios-on-button-click
          const href = window.URL.createObjectURL(response.data);
          const anchorElement = document.createElement('a');
          anchorElement.href = href;
          anchorElement.download = nomFichier;
          document.body.appendChild(anchorElement);
          anchorElement.click();
          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        });
    },
    loadPdfModal(nomPdf, hash, id){
      axios
        .post(process.env.VUE_APP_URL_BACK_END + "/api/fichiers/elementB64", {
          nomPdf: nomPdf,
          hash: hash,
          id: id
        }, {
          headers: {
            "Authorization": "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          },
        })
        .then((response) => {
          this.pdfData = "data:application/pdf;base64," + encodeURI(response.data);
          this.headerModalPdf = "Visualisation de " + nomPdf;
          this.displayModalPdf = true;
        })
        .catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
              this.$toast.add({
                  severity: "error",
                  summary: this.$t('erreur.erreur'),
                  detail: this.$t('erreur.erreurInterne'),
                  life: 3000,
              });
          }
        });
      }
  },
};
</script>