<template>
  <div class="card border-round-bottom-2xl border-2 border-gray-300 border-top-none p-5">
    <div class="text-xl text-900 mb-3 underline">{{ $t('coordonnees.myDetails') }}</div>
    <div class="md:col-4">
      <div class="text-xl text-900 font-medium mb-3">{{ civilite }} {{ nom }} {{ prenom }}</div>
      <div class="text-xl mb-3">{{ metier }}</div>
      <div class="text-xl mb-3">{{ tel }}</div>
      <div class="text-blue-600 text-xl mb-3">{{ mailClient }}</div> 
    </div>
    <div class="flex gap-2">
      <Button :label="$t('coordonnees.modifyDetails')" @click="modifInfos()" />
      <Button :label="$t('coordonnees.modifyEmail')" @click="modifMail()" />
      <Button :label="$t('coordonnees.modifyPassword')" @click="modifUser()" />
    </div>
  </div>
  <Toast />
</template>
<script>

export default {
  data() {
    return {
      prenom: null,
      nom: null,
      tel: null,
      mailClient: null,
      civilite: null,
      metier: null,
      idDolibarr: null,
      address: null,
      ville: null
    };
  },
  mounted() {
    //récupération des informations stockées dans le localStorage et affichés dans les coordonnées de l'utilisateru connecté
    this.prenom = localStorage.getItem("prenom");
    this.nom = localStorage.getItem("nom");
    this.tel = localStorage.getItem("tel");
    this.metier = localStorage.getItem("metier");
    this.civilite = localStorage.getItem("civilite");

    this.mailClient = localStorage.getItem("mail");
    //récupération de l'identifiant Dolibarr 
    // pour le moment inutilisé
    // ultérieurement l'utilisateur pourra modifier les informations de Dolibarr
    this.idDolibarr = localStorage.getItem("idDolibarr");
    localStorage.setItem("contactModifIdLocal", localStorage.getItem("idBDD"));
    localStorage.setItem("contactModifIdDolibarr", this.idDolibarr);
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
  },
  methods: {
    //pour la modification du mot de passe de l'utilisateur
    modifUser() {
      //on renseigne le mail de l'utilisateur à modifier 
      localStorage.setItem("contactModifMail", this.mailClient);
      //puis on le redirige vers la page de modification
      this.$router.push("/modifUtilisateur");
    },
    modifInfos() {
      //on renseigne le mail de l'utilisateur à modifier 
      localStorage.setItem("contactModifMail", this.mailClient);
      //puis on le redirige vers la page de modification
      this.$router.push("/modifInfos");
    },
    modifMail() {
      //on renseigne le mail de l'utilisateur à modifier 
      localStorage.setItem("contactModifMail", this.mailClient);
      //puis on le redirige vers la page de modification
      this.$router.push("/modifMail");
    }
  },
};

</script>