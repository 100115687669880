<template>
    <div class="px-4 py-8 md:px-6 lg:px-8">
      <div
        class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
        {{ $t('modifMail.modifyYourMail') }}
      </div>
      <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
        <ul class="list-none w-12 p-0">
          <li v-if="this.mail === this.mailModif" class="flex flex-wrap align-items-center">
            <p class="font-medium text-900 w-3">{{ $t('modifMail.newMail') }} : </p>
            <InputText type="text" v-model="newMail" :class="{ 'p-invalid': errorNewMail }" />
          </li>
          <li>
            <p class="text-red-500">{{ $t('modifMail.warningChangeMail') }}</p>
          </li>
          <li>
            <div class="flex justify-content-center pt-6">
              <Button type="submit"
                class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
                @click="modifMail()">{{ $t("comment.validate") }}</Button>
              <router-link :to="'/compte/' + (this.mail === this.mailModif ? '0' : '1')" class="no-underline">
                <Button
                  class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">{{ $t("comment.cancel") }}</Button>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Toast />
  </template>
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        errorNewMail: false,
        newMail: "",
        lienCompte: "",
        mail: "",
        mailModif: "",
        idDolibarrModif: "",
        idLocalModif: null,
      }
    },
    mounted() {
      //récupération du token backend dans le local storage
      this.token = localStorage.getItem("token");
      this.mail = localStorage.getItem("mail");
      this.mailModif = localStorage.getItem("contactModifMail");
      this.idDolibarrModif = localStorage.getItem("contactModifIdDolibarr");
      if(localStorage.getItem("contactModifIdLocal") !== undefined) this.idLocalModif = localStorage.getItem("contactModifIdLocal");
      if (this.mail === this.mailModif) {
        this.updateUserPassword = this.$t("modifMdp.updateYourPassword");
      }
      else if (localStorage.getItem("admin") !== undefined && localStorage.getItem("admin")) {
        this.updateUserPassword = this.$t("modifMdp.updatePassword") + ' ' + this.mailModif;
      }
    },
    methods: {
      modifMail() {
        this.errorNewMail = this.newMail === "" || this.newMail.includes('@armonie.group') || this.newMail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null;
        if (!this.errorNewMail) {
          axios.post(
            process.env.VUE_APP_URL_BACK_END + '/api/modifMail',
            {
              email: this.newMail,
              oldMail: this.mail,
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer "+this.token,
              }
            }
          ).then(
            (response) => {
              localStorage.removeItem('contactModifMail');
              localStorage.removeItem('contactModifIdDolibarr');
              localStorage.removeItem('contactModifIdLocal');
              this.$router.push("/login");
            }
          ).catch((e) => {
            //Token invalide ou expiré
            if(e['response']['status'] === 401){
                this.$router.push("/login");
            } else {
            //capture des erreurs revoyées par le back-end
              this.$toast.add({
                  severity: "warn",
                  summary: "Attention",
                  detail: e.response.data.messageErreur,
                  life: 3000,
              });
            }
          })
        }
        else {
          this.$toast.add(
            {
              severity: "warn",
              summary: "Attention",
              detail: "L'email n'est pas valide",
              life: 3000,
            }
          );
        }
      }
    },
  }
  </script>