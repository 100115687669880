<template>
    <div class="px-4 py-8 md:px-6 lg:px-8" v-if="adminPhl">
        <div
            class="text-900 text-4xl font-normal border-round-top-2xl p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700">
            Sélection de l'identifiant du tiers
        </div>
        <div class="surface-card p-4 border-round-bottom-2xl border-2 border-500">
            <div class="flex flex-column p-fluid">
                <div class="flex align-items-center">
                    <label for="sujet" class="font-medium text-900 w-2">Identifiant</label>
                    <InputNumber name="idTiers" id="idTiers" v-model="idTiers" onchange="sendIdTiers()"
                        :class="{ 'p-invalid': erreurIdTiers }" />
                </div>
            </div>
            <div class="flex justify-content-center">
                <Button type="submit" id="click"
                    class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
                    @click="sendIdTiers()">Rechercher</Button>
            </div>
            <div v-if="trouvee" class="p-2">
                <hr>
                <div class=" flex justify-content-around mt-3">
                    <p class="p-0 m-0 mt-4">Entreprise trouvée : {{ nomEntreprise }}</p>
                    <Button @click="confirmTiers()" class="border-round-2xl justify-content-center align-items-center mt-3" severity="success">
                        Continuer vers cette entreprise
                        <i class="pi pi-angle-right"></i>
                    </Button>
                </div>
                <div class="flex justify-content-center">
                    <p class="text-red-400">La page va se recharger avec les données de l'entreprise sélectionnée et les menus intégreront ces mêmes données.</p>
                </div>
            </div>
        </div>
    </div>
    <Toast />
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            adminPhl: false,
            nomEntreprise: "",
            trouvee: false,
            idTiers: 0,
            erreurIdTiers: false
        }
    },
    mounted() {
        this.adminPhl = localStorage.getItem('adminPhl') !== undefined && localStorage.getItem('adminPhl') === 'true';
        this.token = localStorage.getItem("token");
    },
    methods: {
        sendIdTiers() {
            if (this.idTiers === undefined || this.idTiers === 0 || this.idTiers === "" || this.idTiers === null) {
                this.erreurIdTiers = true;
            }
            else {

                axios
                    .post(
                        process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/findSoc",
                        {
                            idTiers: this.idTiers,
                            
                        },
                        {
                            headers: {
                                //on envoie le token d'authentification et le format des données envoyés en POST
                                "Content-Type": "multipart/form-data",
                                "Authorization": "Bearer " + this.token,
                            }
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            this.trouvee = true;
                            this.nomEntreprise = res['data']['name'];
                            this.$toast.add({
                                severity: "success",
                                summary: "Entreprise trouvée",
                                life: 3000,
                            });
                        }
                        else if (res.status === 204) {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Pas d'entreprise trouvée",
                                life: 3000,
                            });
                        }
                    }).catch((e) => {
                        //Token invalide ou expiré
                        if (e['response']['status'] === 401) {
                            this.$router.push("/login");
                        }
                        //capture des erreurs renvoyées par le back-end
                        else {
                            this.$toast.add({
                                severity: "error",
                                summary: this.$t('erreur.erreur'),
                                detail: this.$t('erreur.erreurInterne'),

                                life: 3000,
                            });
                        }
                    });
            }
        },
        confirmTiers(){
            localStorage.setItem("idTiers", this.idTiers);
            localStorage.setItem("nomEntreprise", this.nomEntreprise);
            location.reload();
        }
    }
}
</script>