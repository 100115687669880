<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div
      class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
      {{ this.updateUserPassword }}
    </div>
    <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
      <ul class="list-none w-12 p-0">
        <li v-if="this.mail === this.mailModif" class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("modifMdp.oldPassword") }}</p>
          <Password toggleMask type="text" :pt="{
            panel: { class: 'hidden' },
          }" v-model="oldPwd" :class="{ 'p-invalid': errorOldPassword }" />
          <!-- <small class="p-error">{{
            errorMdp ? "Un mot de passe est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("modifMdp.newPassword") }}</p>
          <Password toggleMask type="text" :pt="{
            panel: { class: 'hidden' },
          }" v-model="mdp" :class="{ 'p-invalid': errorMdp }" />
          <!-- <small class="p-error">{{
            errorMdp ? "Un mot de passe est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="font-medium text-900 w-3">{{ $t("modifMdp.confirmNewPassword") }}</p>
          <Password toggleMask type="text" :pt="{
            panel: { class: 'hidden' },
          }" v-model="mdp2" :class="{ 'p-invalid': errorMdp2 }" />
          <!-- <small class="p-error">{{
            errorMdp ? "Un mot de passe est nécessaire" : "&nbsp;"
          }}</small> -->
        </li>
        <li>
          <div class="flex justify-content-center pt-6">
            <Button type="submit"
              class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
              @click="modifUser()">{{ $t("comment.validate") }}</Button>
            <router-link :to="'/compte/' + (this.mail === this.mailModif ? '0' : '1')" class="no-underline">
              <Button
                class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">{{ $t("comment.cancel") }}</Button>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <Toast />
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      errorMdp: false,
      errorMdp2: false,
      errorOldPassword: false,
      oldPwd: "",
      mdp2: "",
      mdp: "",
      updateUserPassword: "",
      lienCompte: "",
      mail: "",
      mailModif: "",
      idDolibarrModif: "",
      idLocalModif: null,
    }
  },
  mounted() {
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    this.mail = localStorage.getItem("mail");
    this.mailModif = localStorage.getItem("contactModifMail");
    this.idDolibarrModif = localStorage.getItem("contactModifIdDolibarr");
    if(localStorage.getItem("contactModifIdLocal") !== undefined) this.idLocalModif = localStorage.getItem("contactModifIdLocal");
    if (this.mail === this.mailModif) {
      this.updateUserPassword = this.$t("modifMdp.updateYourPassword");
    }
    else if (localStorage.getItem("admin") !== undefined && localStorage.getItem("admin")) {
      this.updateUserPassword = this.$t("modifMdp.updatePassword") + ' ' + this.mailModif;
    }
  },
  methods: {
    modifUser() {
      if (this.mail === this.mailModif) {
        this.errorOldPassword = this.oldPwd === "";
      }
      if (!this.errorOldPassword) {
        this.errorMdp = this.mdp === "";
        this.errorMdp2 = this.mdp2 === "";
        if (this.mdp2 === this.mdp) {
          //on vérife que les champs sont remplis
          if (!this.errorMdp || !this.errorMdp2) {
            //si c'est le cas on envoie la demande au back-end
            axios.post(
              process.env.VUE_APP_URL_BACK_END + '/api/modifMdp',
              {
                oldPassword: this.oldPwd,
                password: this.mdp,
                idDolibarr : this.idDolibarrModif,
                email: this.mailModif,
                admin: localStorage.getItem("admin") !== undefined && localStorage.getItem("admin")
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  "Authorization": "Bearer "+this.token,
                }
              }
            ).then(
              (response) => {
                localStorage.removeItem('contactModifMail');
                localStorage.removeItem('contactModifIdDolibarr');
                localStorage.removeItem('contactModifIdLocal');
                this.$router.push("/compte/1");
              }
            ).catch((e) => {
              //Token invalide ou expiré
              if(e['response']['status'] === 401){
                  this.$router.push("/login");
              } else {
              //capture des erreurs revoyées par le back-end
                this.$toast.add({
                    severity: "warn",
                    summary: "Attention",
                    detail: e.response.data.messageErreur,
                    life: 3000,
                });
              }
            })
          }
        }
        else {
          this.$toast.add(
            {
              severity: "warn",
              summary: "Attention",
              detail: "Les 2 nouveaux mots de passe ne correspondent pas.",
              life: 3000,
            }
          );
        }
      }
    }
  },
}
</script>