<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    window.document.title = this.$t('app.clientSpacePHL');
  },
};
</script>
<style>
@import url("https://fonts.cdnfonts.com/css/poppins");

body {
  font-family: "poppins" !important;
  margin: 0;
}
.gradientArmonie {
  background: rgb(55, 48, 152);
  background: linear-gradient(
    90deg,
    rgba(55, 48, 152, 1) 0%,
    rgba(30, 88, 161, 1) 45%,
    rgba(0, 137, 173, 1) 100%
  );
}
.bg-gradientArmonie:hover {
  background: rgb(55, 48, 152);
  background: linear-gradient(
    90deg,
    rgba(55, 48, 152, 1) 0%,
    rgba(30, 88, 161, 1) 45%,
    rgba(0, 137, 173, 1) 100%
  );
}
.poppins {
  font-family: "poppins" !important;
}
</style>
