<template>
    <div class="px-4 py-8 md:px-6 lg:px-8">
      <div
        class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
        {{ $t('modifInfos.modifyYourDetails') }}
      </div>
      <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
        <ul class="list-none w-12 p-0">
          <li class="flex flex-wrap align-items-center">
            <p class="font-medium text-900 w-3">{{ $t("createUser.lastname") }}</p>
            <InputText type="text" v-model="nom"/>
          </li>
          <li class="flex flex-wrap align-items-center">
            <p class="font-medium text-900 w-3">{{ $t("createUser.name") }}</p>
            <InputText type="text" v-model="prenom"/>
          </li>
          <li class="flex flex-wrap align-items-center gap-2">
            <p class="font-medium text-900 w-3">{{ $t("createUser.gender") }}</p>
            <div class="flex align-items-center">
              <RadioButton v-model="civilite" inputId="femme" name="femme" value="MME"/>
              <label for="femme" class="ml-2">{{ $t('createUser.woman') }}</label>
            </div>
            <div class="flex align-items-center">
              <RadioButton v-model="civilite" inputId="homme" name="homme" value="MR"/>
              <label for="homme" class="ml-2">{{ $t('createUser.man') }}</label>
            </div>
          </li>
          <li class="flex flex-wrap align-items-center">
            <p class="font-medium text-900 w-3">{{ $t("createUser.job") }}</p>
            <InputText type="text" v-model="metier"/>
          </li>
          <li class="flex flex-wrap align-items-center">
            <p class="font-medium text-900 w-3">{{ $t("createUser.phone") }}</p>
            <InputText type="text" v-model="tel"/>
          </li>
          <li>
            <div class="flex justify-content-center align-content-center">
              <Button type="submit" class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3" @click="creation()">
                {{ $t("comment.validate") }}
              </Button>
              <router-link :to="'/compte/0'" class="no-underline">
                <Button class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">
                  {{ $t("comment.cancel") }}
                </Button>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Toast />
  </template>
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        mail: "",
        prenom: "",
        nom: "",
        tel: "",
        metier: "",
        civilite: "",
      };
    },
    mounted() {
      //récupération du token backend dans le local storage
      this.token = localStorage.getItem("token");
      this.mail = localStorage.getItem("mail");
      if(localStorage.getItem('nom') !== 'null') this.nom = localStorage.getItem('nom');
      if(localStorage.getItem('prenom') !== 'null') this.prenom = localStorage.getItem('prenom');
      if(localStorage.getItem('tel') !== 'null') this.tel = localStorage.getItem('tel');
      if(localStorage.getItem('metier') !== 'null') this.metier = localStorage.getItem('metier');
      if(localStorage.getItem('civilite') !== 'null') this.civilite = localStorage.getItem('civilite');
      
    },
    methods: {
      creation() {
        // Rafraichissement des données en session
        localStorage.setItem("nom", this.nom);
        localStorage.setItem("prenom", this.prenom);
        localStorage.setItem("metier", this.metier);
        localStorage.setItem("tel", this.tel);
        localStorage.setItem("civilite", this.civilite);

          axios
            .post(
              process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/updateContact",
              {
                mail: this.mail,
                nom: this.nom,
                prenom: this.prenom,
                metier: this.metier,
                tel: this.tel,
                civilite: this.civilite,
              },
              {
                headers: {
                  //on envoie le token d'authentification et le format des données envoyés en POST
                  "Content-Type": "multipart/form-data",
                  "Authorization": "Bearer "+this.token,
                },
              }
            )
            .then((response) => {
              if(response.status === 200){
                this.$router.push("/compte/0");
              }
            }).catch((e) => {
              //Token invalide ou expiré
              if(e['response']['status'] === 401){
                  this.$router.push("/login");
              }
              //capture des erreurs revoyées par le back-end
              else{
                  this.$toast.add({
                      severity: "warn",
                      summary: this.$t('erreur.erreur'),
                      detail: this.$t('erreur.erreurInterne'),
                      life: 3000,
                  });
              }
            });
      },
    },
  };
  </script>
  