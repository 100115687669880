<template>
  <div class="flex flex-column w-full h-full p-2">
    <h1 class="pl-5 text-700 font-normal">{{ $t("accueil.quickAccess") }}</h1>
    <div class="border-round-2xl border-2 border-500 w-11">
      <div class="flex">
        <ul class="list-none p-2 my-0">
          <h2 class="font-normal text-700">{{ $t("accueil.tickets") }}</h2>
          <li class="flex">
            <a href="/ouvrirTicket"
              class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
              <i class="pi pi-tag" style="font-size: 2.5rem;"></i>
              <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.openNewTicket") }}</span>
            </a>
          </li>
        </ul>
          <ul class="list-none p-2 my-0">
            <h2 class="font-normal text-700">{{ $t("accueil.forums") }}</h2>
            <li class="md:flex">
            <li class="flex">
              <a href="/ouvrirDiscussion"
                class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
                <i class="pi pi-comments" style="font-size: 2.5rem;"></i>
                <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.openNewChat") }}</span>
              </a>
            </li>
            <!-- <li class="flex">
              <a href="/ouvrirDiscussion"
                class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
                <i class="pi pi-comments" style="font-size: 2.5rem;"></i>
                <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.makeSuggestion") }}</span>
              </a>
            </li> -->
            </li>
        </ul>
      </div>
      <ul class="list-none p-2 my-0">
        <h2 class="font-normal text-700">{{ $t("accueil.account") }}</h2>
        <li class="md:flex md:jsutify-content-center">
        <li class="flex md:w-2">
          <a href="/compte/0"
            class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
            <i class="pi pi-id-card" style="font-size: 2.5rem;"></i>
            <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.modifyCoordinates") }}</span>
          </a>
        </li>
        <li class="flex md:w-2">
          <a href="/compte/1"
            class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
            <i class="pi pi-user" style="font-size: 2.5rem;"></i>
            <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.manageUsers") }}</span>
          </a>
        </li>
        <li class="flex md:w-2">
          <a href="/compte/2"
            class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
            <i class="pi pi-file" style="font-size: 2.5rem;"></i>
            <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.consultContracts") }}</span>
          </a>
        </li>
        <li class="flex md:w-2">
          <a href="/compte/3"
            class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
            <i class="pi pi-key" style="font-size: 2.5rem;"></i>
            <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.manageKeys") }}</span>
          </a>
        </li>
        <li class="flex md:w-2">
          <a href="/compte/4"
            class="flex w-full no-underline flex-column p-2 align-items-center cursor-pointer text-600 hover:text-900 border-round transition-colors transition-duration-150">
            <i class="pi pi-folder" style="font-size: 2.5rem;"></i>
            <span class="font-medium text-center p-1 pt-2">{{ $t("accueil.consultInvoices") }}</span>
          </a>
        </li>
        </li>
      </ul>
    </div>
    <div class="flex flex-column justify-content-center">
      <h1 class="pl-5 text-700 font-normal">{{ $t("accueil.PHLAnnouncement") }}</h1>
      <p v-html="annonce" class="gradientArmonie border-transparent border-round-2xl md:w-11 p-3 text-white"></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      annonce: this.$t("accueil.PHLAnnouncementContent")
    }
  },
  mounted() {
    //Si des liens sont renseignés dans les annonces, on construit le HTML correspondant plutôt que de les renseigner dans le JSON
    //Les liens dans le JSON sont construits de cette façon : (((url,alias)))
    //let indexLinkStart = this.annonce.indexOf('(((');
    //let indexLinkEnd = this.annonce.indexOf(')))');
    //while(indexLinkStart !== -1 && indexLinkEnd !== -1){
    //  let element = this.annonce.substring(indexLinkStart + 3, indexLinkEnd);
    //  let url = element.split(',')[0].replace('(((', '')
    //  let alias = element.split(',')[1].replace(')))', '');
    //  this.annonce = this.annonce.replace("(((" + element + ")))", "");
    //  this.annonce = this.annonce.slice(0, indexLinkStart) + '<a href="' + url + '" class="underline text-white cursor-pointer">' + alias + '</a>' + this.annonce.slice(indexLinkStart);
    //  indexLinkStart = this.annonce.indexOf('(((');
    //  indexLinkEnd = this.annonce.indexOf(')))');
    //}
    fetch('annonce.json')
      .then(response => response.json())
      .then(jsonResponse => {
        if(jsonResponse[this.$i18n.locale] !== undefined){
          this.annonce = jsonResponse[this.$i18n.locale];
        }
        else{
          this.annonce = jsonResponse['en'];
        }
      });
  }
};
</script>