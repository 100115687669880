<script setup>
import { useToast } from "primevue/usetoast";
import axios from "axios";
const toast = useToast();
</script>

<template>
  <Nav />
  <div class="card">
    <div class="flex justify-content-center flex-wrap card-container">
      <div
        class="flex w-full h-25rem md:w-5 p-3 md:p-4 flex-column justify-content-around">
        <div class="flex justify-content-center text-center">
          <i>{{ $t('passwordForgot.info') }} : </i>
        </div>
        <div>
          <span class="p-float-label">
            <InputText type="text" v-model="email" :class="['w-full p-2 poppins', { 'p-invalid': errorEmail }]" />
            <label style="font-style: italic">{{ $t('connexion.login') }}</label>
          </span>
          <small class="p-error">{{
            errorEmail ? $t("passwordForgot.requiredEmail") : "&nbsp;"
          }}</small>
          <div class="flex flex-column align-items-center h-9rem w-full justify-content-between">
            <Button id="sendMail" type="submit" rounded @click="sendEmail()">{{ $t('passwordForgot.sendMail')
            }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/TopBar.vue';
export default {
  components: {
    Nav
  },
  data() {
    return {
      email: "",
      errorEmail: false
    }
  },
  methods: {
    sendEmail() {
      if(this.email !== "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(this.email)){
        this.errorEmail = false;
      } else {
        this.errorEmail = true;
      }
      if (!this.errorEmail) {
        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/sendMailResetPassword",
            {
              email: this.email,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => {
            this.$toast.add({
              severity: "info",
              summary: this.$t("passwordForgot.mailSent"),
              detail: this.$t("passwordForgot.mailSentDetail"),
              life: 10000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("erreur.erreur"),
              detail:  this.$t("erreur.erreurInterne"),
              life: 3000,
            });
          });
      }
    }
  }
};
</script>