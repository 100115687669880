<script setup>
import axios from "axios";
import { useToast } from "primevue/usetoast";

const toast = useToast();

let icon = 'pi pi-upload';

const customBase64Uploader = async (event) => {
  let click = document.getElementById('click');
  icon = 'pi pi-spin pi-spinner';
  const file = event.files[0];
  let extensionInterdite = file.name.lastIndexOf('.exe');
  if (extensionInterdite === -1){
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/moveFiles",
        {
          file: file,
          nameFile: file.name,
          idClientPhl: localStorage.getItem("idTiers")
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          icon = 'pi pi-upload';
          toast.add({
            severity: "success",
            summary: "Pièce jointe uploadée",
            detail: file.name,
            life: 3000,
          });
          click.focus();
        }
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                life: 3000,
            });
            icon = 'pi pi-upload';
            click.focus();
        }
      });
    }
    else {
      toast.add({
          severity: "error",
          summary: "Fichier non autorisé (.exe)",
          life: 3000,
        });
        icon = 'pi pi-upload';
        click.focus();
    }
};
</script>
<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div
      class="text-900 text-4xl font-normal border-round-top-2xl p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700">
      {{ $t("accueil.openNewTicket") }}
    </div>
    <div class="surface-card p-4 border-round-bottom-2xl border-2 border-500">
      <div class="flex flex-column p-fluid">
        <div class="flex align-items-center">
          <label for="sujet" class="font-medium text-900 w-2">{{ $t('tickets.subject') }}</label>
          <InputText type="text" v-model="titre" :class="['w-full md:w-14rem', { 'p-invalid': errorTitre }]" />
          <!-- <small class="p-error">{{
            errorTitre ? "Un titre est requis." : "&nbsp;"
          }}</small> -->
        </div>
        <div class="flex flex-column mb-3 w-12"></div>
        <div class="flex align-items-center">
          <label for="rubrique" class="font-medium text-900 w-2">{{ $t('tickets.section') }}</label>
          <Dropdown v-model="selectedRubrique" :options="rubriques" optionLabel="name" name="rubrique"
            :class="['w-full md:w-14rem', { 'p-invalid': errorRubrique }]" />
        </div>
        <div class="flex flex-column mb-3 w-12"></div>
        <div class="flex align-items-center">
          <label for="radio" class="font-medium text-900 w-2">{{ $t('createTicket.ticketType') }}</label>
          <div class="flex flex-wrap gap-3" id="radio">
            <div class="flex align-items-center">
              <RadioButton v-model="type" inputId="bug" name="type" value="10022" :class="{ 'p-invalid': errorType }" />
              <label for="bug" class="ml-2">{{ $t("createTicket.incident") }}</label>
            </div>
            <div class="flex align-items-center">
              <RadioButton v-model="type" inputId="tache" name="type" value="10020" :class="{ 'p-invalid': errorType }" />
              <label for="tache" class="ml-2">{{ $t("createTicket.workRequest") }}</label>
            </div>
            <!-- a ajouter plus tard suivant les reunions -->
            <!-- <div class="flex align-items-center">
                <RadioButton
                  v-model="type"
                  inputId="prestation"
                  name="type"
                  value="10020"
                  :class="{ 'p-invalid': errorType }"
                />
                <label for="prestation" class="ml-2">Prestation</label>
                </div>
                <div class="flex align-items-center">
                  <RadioButton
                    v-model="type"
                    inputId="formation"
                    name="type"
                    value="10020"
                    :class="{ 'p-invalid': errorType }"
                  />
                  <label for="formation" class="ml-2">Formation</label>
                </div>
              <small class="p-error">{{
            errorType ? "Un type est requis." : "&nbsp;"
           }}</small> -->
          </div>
        </div>
        <div class="flex flex-column mb-3 w-12"></div>
        <div class="flex align-items-center">
          <label for="file" class="font-medium text-900 w-2">{{ $t("ticketHistory.attachment") }}</label>
          <FileUpload id="file" mode="basic"
            class="md:w-12rem p-button-outlined p-button-plain bg-indigo-500 text-0 border-round-2xl"
            :chooseLabel="$t('comment.add')" :auto="true" customUpload @uploader="customBase64Uploader">
            <template #uploadicon>        
              <i :class="icon"></i>   
            </template>
          </FileUpload>
        </div>
        <div class="flex flex-column mb-3 w-12"></div>
        <div class="flex align-items-start">
          <label for="description" class="font-medium text-900 w-2">{{ $t("createTicket.description") }}</label>
            <Editor v-model="description" editorStyle="height: 320px" class="w-full md:w-10">
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                </span>  
              </template>
            </Editor>
        </div>
      </div>
      <div class="flex justify-content-center">
        <Button type="submit" id="click"
          class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
          @click="onSubmit()">{{ $t("comment.validate") }}</Button>
        <Button
          class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3"
          @click="supprFiles()">{{ $t("comment.cancel") }}</Button>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>
export default {
  data() {
    return {
      titre: "",
      description: "",
      type: "",
      selectedRubrique: "",
      infosDolibarr: "",
      idTicket: null,
      //pour les erreurs de saisies -> false à la base vu qu'il n'a rien saisi
      // errorDesc: false,
      errorTitre: false,
      errorType: false,
      errorRubrique: false,
      //Liste des différentes rubriques possibles
      rubriques: [],
      libelles: [],
    };
  },
  mounted () {
    //capture du bouton retour pour supprimer les pièces jointes
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    // récupération des catégories de tickets possibles
    this.libelles = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    for(var key in this.libelles){
      if(this.libelles[key]["jira"] !== "0"){
        this.rubriques.push({"name": this.libelles[key]["label"], "code": this.libelles[key]["jira"]});
      }
    }
    var self = this;
    window.onpopstate = function(event) {
      self.supprFiles();
    }
  },
  methods: {
    // html2adf(){
    //   // var documentFragment = this.createElementFromHTML(tinymce.activeEditor.getContent());
    //   var documentFragment = this.createElementFromHTML(this.description);
    //   var jsonNodes = this.htmlToJsonNodes(documentFragment)          

    //   var adf = {
    //     "version": 1,
    //     "type": "doc",
    //     "content": this.jsonNodesToADF(jsonNodes)
    //   }
    //   return adf;
    // },
    // createElementFromHTML(htmlString) {
    //   var div = document.createElement('div');
    //   div.innerHTML = htmlString.trim();

    //   // Change this to div.childNodes to support multiple top-level nodes.
    //   return div;
    // },
    // htmlToJsonNodes(obj){
    //   var node = {
    //     'name': obj.nodeName,
    //     'value': obj.nodeValue,
    //     'type': obj.nodeType,
    //     'children': [],
    //   }
    //   for (var i = 0; i< obj.childNodes.length;i++){
    //     node['children'].push(this.htmlToJsonNodes(obj.childNodes[i]))
    //   }
    //   if (obj.nodeName == "A") {
    //     node["href"] = obj.href
    //   }
    //   return node
    // },
    // jsonNodesToADF(obj){
    //   var adf_contents = []
    //   if (obj.hasOwnProperty("name") && obj.hasOwnProperty("value") && obj.hasOwnProperty("type")) {
    //     var contents = this.jsonNodeToADF(obj)
    //     adf_contents = adf_contents.concat(contents)

    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       adf_contents = adf_contents.concat(contents)
    //     }
    //   }
    //   return adf_contents
    // },
    // jsonNodeToADF(obj){
    //   var adf_contents = []

    //   // paragraph
    //   if (obj.name == "P" && obj.type == 1){
    //     var adf_obj = {
    //       "type": "paragraph",
    //       "content": [],
    //     }
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       adf_obj["content"] = adf_obj["content"].concat(contents)
    //     }
    //     adf_contents.push(adf_obj)

    //   } else 
      
    //   // text
    //   if (obj.name == "#text" && obj.type == 3){
    //     var adf_obj = {
    //       "type": "text",
    //     }
    //     if (obj.value != "\n") {
    //       adf_obj["text"] = obj.value
    //       adf_contents.push(adf_obj)
    //     }

    //   } else

    //   // break new line
    //   if (obj.name == "BR" && obj.type == 1){
    //     var adf_obj = {
    //       "type": "hardBreak",
    //     }
    //     adf_contents.push(adf_obj)

    //   } else

    //   // bold
    //   if (obj.name == "STRONG" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "strong",
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // italic
    //   if (obj.name == "EM" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "em",
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else 

    //   // strike
    //   if (obj.name == "S" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "strike",
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // underline
    //   if (obj.name == "SPAN" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "underline",
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else 

    //   // superscript
    //   if (obj.name == "SUP" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "subsup",
    //           "attrs": {
    //             "type": "sup"
    //           }
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // subscript
    //   if (obj.name == "SUB" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "subsup",
    //           "attrs": {
    //             "type": "sub"
    //           }
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // code
    //   if (obj.name == "CODE" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "code",
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // link
    //   if (obj.name == "A" && obj.type == 1){
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       for (var i = 0 ; i < contents.length ; i++){
    //         var contentItem = contents[i]
    //         if (!contentItem.hasOwnProperty("marks")){
    //           contentItem["marks"] = []
    //         }
    //         contentItem["marks"].push({
    //           "type": "link",
    //           "attrs": {
    //             "href": obj.href
    //           }
    //         })
    //         adf_contents.push(contentItem)
    //       }
    //     }

    //   } else

    //   // headers
    //   if (obj.type == 1 && obj.name == "H1" || obj.name == "H2" || obj.name == "H3" || obj.name == "H4" || obj.name == "H5"){
    //     var adf_obj = {
    //       "type": "heading",
    //       "attrs": {
    //         "level": obj.name[1],
    //       },
    //       "content": [],
    //     }
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       adf_obj["content"] = adf_obj["content"].concat(contents)
    //     }
    //     adf_contents.push(adf_obj)

    //   } else

    //   // unosorted list
    //   if (obj.name == "UL" && obj.type == 1){
    //     var adf_obj = {
    //       "type": "bulletList",
    //       "content": [],
    //     }
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       adf_obj["content"] = adf_obj["content"] .concat(contents)
    //     }
    //     adf_contents.push(adf_obj)
        
    //   } else

    //   // order list
    //   if (obj.name == "OL" && obj.type == 1){
    //     var adf_obj = {
    //       "type": "orderedList",
    //       "content": [],
    //     }
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       adf_obj["content"] = adf_obj["content"] .concat(contents)
    //     }
    //     adf_contents.push(adf_obj)
        
    //   } else

    //   // list item
    //   if (obj.name == "LI" && obj.type == 1){
    //     var adf_obj = {
    //       "type": "listItem",
    //       "content": [{
    //           "type": "paragraph",
    //           "content": [],
    //       }],
    //     }
    //     for (var i = 0 ; i < obj.children.length ; i++){
    //       var last_paragraph = adf_obj["content"].length - 1
    //       var contents = this.jsonNodeToADF(obj.children[i])
    //       if (contents.length > 0){
    //         adf_obj["content"][last_paragraph]["content"] = adf_obj["content"][last_paragraph]["content"].concat(contents)
    //       }
    //     }
    //     adf_contents.push(adf_obj)

    //   }

    //   return adf_contents
    // },
    onSubmit() {
      this.idClientPHL = localStorage.getItem("idTiers");
      this.infosDolibarr = localStorage.getItem("infosDolibarr");
      this.mailClient = localStorage.getItem("mail");
      
      //vérification des inputs de l'utilisateur
      this.errorTitre = this.titre.length < 3;
      // this.errorDesc = this.description.length < 3;
      this.errorRubrique = this.selectedRubrique === "";
      this.errorType = this.type === "";

      //lancement de la requête si tout les inputs sont 'valides'
      if (
        !this.errorType &&
        !this.errorTitre &&
        // !this.errorDesc &&
        !this.errorRubrique
      ) {
        //on créer le ticket avec les informations du formulaire
        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/api/jira/create",
            {
              titre: this.titre,
              idTypeTicket: this.type,
              description: this.description,
              rubrique: this.selectedRubrique.code,
              infosDolibarr: this.infosDolibarr,
              visibilite: process.env.VUE_APP_ID_PRIVATE_JIRA,
              mailClient: this.mailClient,
              idClientPhl: this.idClientPHL
            },
            {
              headers: {
                //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+this.token,
              }
            }
          )
          .then((res) => {
            if (res.status === 200) {
              this.$toast.add({
                severity: "info",
                summary: "Ticket envoyé",
                life: 3000,
              });
              this.idTicket = res["data"]["key"];
              this.$router.push("/ticket/" + this.idTicket);
            }
          }).catch((e) => {
            //Token invalide ou expiré
            if(e['response']['status'] === 401){
                this.$router.push("/login");
            }
            //capture des erreurs revoyées par le back-end
            else{
                this.$toast.add({
                    severity: "error",
                    summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                    life: 3000,
                });
            }
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Attention",
          detail: "Au moins un champ obligatoire n'a pas été saisi",
          life: 3000,
        });
      }
    },
    supprFiles() {
      this.client = localStorage.getItem("idTiers");
      axios.post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/supprFiles", {
        client: this.client
      },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token,
          }
        }
      ).then((res) => {
        this.$router.push("/mesTickets");
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                life: 3000,
            });
        }
      })
    },
  },
};
</script>
