<script setup>
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const options = { year: "numeric", month: "numeric", day: "numeric" };

const getDateFormat = (date) => {
  date = new Date(Date.parse(date));
  return (
    date.toLocaleDateString(undefined, options) +
    " à " +
    date.toLocaleTimeString("fr-FR")
  );
};
</script>

<template>
  <div>
    <div class="card mb-4">
      <div class="flex align-items-center">
        <p class="text-4xl pr-4 pl-2 text-700">{{ $t('sideBar.myTickets') }}</p>
        <router-link :to="'/ouvrirTicket'" class="no-underline">
          <Button class="border-round-xl h-2rem">{{ $t('tickets.newTicket') }}</Button>
        </router-link>
      </div>
      <div class="h-full p-2">
        <TabView :pt="{
          panelContainer: () => ({
            class: 'p-0',
          }),
        }">
          <TabPanel :header="$t('tickets.inProgress')" :pt="{
            header: () => ({
              class:
                'border-left-2 border-top-2 border-right-2 border-gray-300',
            }),
          }">
            <div class="border-2 border-gray-300 border-top-none">
              <DataTable :loading="loading" ref="dt" :value="tickets" v-model:filters="filters" dataKey="id" :paginator="true" :rows="10"
                :filters="filters" :rowsPerPageOptions="[5, 10, 15]" :pt="{
                  header: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                  paginator: () => ({
                    class:
                      'bg-white border-round-bottom-2xl border-right-none border-left-none',
                  }),
                }">
                <template #header>
                  <div class="flex flex-wrap gap-2 align-items-center justify-content-center">
                    <span class="p-input-icon-right">
                      <InputText v-model="filters['global'].value" :placeholder="$t('tickets.search') + '...'" />
                      <i class="pi pi-search" />
                    </span>
                    <span class="p-input-icon-right">
                      <InputText v-model="filters['key'].value" placeholder="N°" />
                      <i class="pi pi-search" />
                    </span>
                  </div>
                </template>
                <Column field="key" header="N°" sortable style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column field="fields.created" :header="$t('tickets.dateCreate')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.created) }}
                  </template>
                </Column>
                <Column field="fields.updated" :header="$t('tickets.lastActivity')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.updated) }}
                  </template>
                </Column>
                <Column :header="$t('tickets.section')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"><template #body="slotProps" >
                  <div v-if="slotProps.data.fields.customfield_10082 !== null">
                      {{ tableau[slotProps.data.fields.customfield_10082.value] ? tableau[slotProps.data.fields.customfield_10082.value]["label"] : slotProps.data.fields.customfield_10082.value }}
                    </div>
                  </template></Column>
                <Column field="fields.summary" :header="$t('tickets.subject')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column :header="$t('tickets.consult')" style="min-width: 5rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                      <router-link :to="'/ticket/' + slotProps.data.key">
                        <i class="pi pi-eye cursor-pointer"
                          style="color: rgb(9, 12, 198); font-size: 1.5rem" /></router-link>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel :header="$t('tickets.archived')" :pt="{
            header: () => ({
              class:
                'border-left-2 border-top-2 border-right-2 border-gray-300',
            }),
          }">
            <div class="border-2 border-gray-300 border-top-none">
              <DataTable :loading="loadingArchived" ref="dt" :value="ticketsArchive" v-model:filters="filters" dataKey="id" :paginator="true"
                :rows="10" :filters="filters" :rowsPerPageOptions="[5, 10, 15]" :pt="{
                  header: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                <template #header>
                  <div class="flex flex-wrap gap-2 align-items-center justify-content-center">
                    <span class="p-input-icon-right">
                      <InputText v-model="filters['global'].value" :placeholder="$t('tickets.search') + '...'" />
                      <i class="pi pi-search" />
                    </span>
                    <span class="p-input-icon-right">
                      <InputText v-model="filters['key'].value" placeholder="N°" />
                      <i class="pi pi-search" />
                    </span>
                  </div>
                </template>
                <Column field="key" header="N°" sortable style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column field="fields.created" :header="$t('tickets.dateCreate')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.created) }}
                  </template>
                </Column>
                <Column field="fields.updated" :header="$t('tickets.lastActivity')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.updated) }}
                  </template>
                </Column>
                <Column field="fields.customfield_10082" :header="$t('tickets.section')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"><template #body="slotProps" >
                    <div v-if="slotProps.data.fields.customfield_10082 !== null">
                      {{ tableau[slotProps.data.fields.customfield_10082.value] ? tableau[slotProps.data.fields.customfield_10082.value]["label"] : slotProps.data.fields.customfield_10082.value }}
                    </div>
                  </template>
                </Column>
                <Column field="fields.summary" :header="$t('tickets.subject')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column :header="$t('tickets.consult')" style="min-width: 5rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                      <router-link :to="'/ticket/' + slotProps.data.key">
                        <i class="pi pi-eye cursor-pointer"
                          style="color: rgb(9, 12, 198); font-size: 1.5rem" /></router-link>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>
export default {
  data() {
    return {
      tickets: null,
      ticketsArchive: null,
      filters: {},
      loading: true,
      loadingArchived: true
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.idClientPHL = localStorage.getItem("idTiers");
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    this.tableau = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/client",
        {
          idClientPHL: this.idClientPHL,
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token,
          },
        }
      )
      .then((response) => {
        this.tickets = response["data"];
        this.loading = false;
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),

                life: 3000,
            });
        }
      });
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/status",
        {
          idStatus: process.env.VUE_APP_STATUS_JIRA_TICKET_TERMINE,
          idClientPHL: this.idClientPHL,
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token,
          },
        }
      )
      .then((response) => {
        this.ticketsArchive = response["data"];
        this.loadingArchived = false;
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      });
  },
  methods: {
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "fields.project.name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        key: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
