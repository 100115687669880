<template>
  <footer class="bottom-0 text-center">
    <p>
      <a>PHL Soft {{ new Date().getFullYear() }}</a
      >
      |
      <a
        href="/documentation_legale/cuphl.pdf" 
        download
        class="cursor-pointer hover:text-purple-600 no-underline text-900"
        >{{ $t("footer.useTerms") }}</a
      >
      |
      <a
        href="/documentation_legale/cgvphl.pdf" 
        download
        class="cursor-pointer hover:text-purple-600 no-underline text-900"
        >{{ $t("footer.gtc") }}</a
      >
      <!-- |
      <a
        @click="reglementDonneesPerso()"
        class="cursor-pointer hover:text-purple-700"
        >{{ $t("footer.gdpr") }}</a
      > -->
    </p>
  </footer>
</template>
<script>
export default {
  methods: {
    phlSite() {
      this.$router.push("https://www.phlsoft.com/");
    },
  },
};
</script>
