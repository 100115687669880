<script setup>
import { useToast } from "primevue/usetoast";
const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
const toast = useToast();

const getDateFormat = (date) => {
  date = new Date(date * 1000);
  return (
    date.toLocaleDateString(undefined, options)
  );
};

const getSeverity = (paye) => {
  // si paye = 0 alors la facture n'est pas payée ou pas entièrement
  if (paye === "0") {
    return 'danger';
  }
  else {
    return 'success';
  }
};

const getEtat = (paye) => {
  if (paye === "0") {
    return 'impaye';
  }
  else {
    return 'paye';
  }
};
</script>

<template>
  <div class="card">
    <DataTable :value="factures" paginator sortField="date" :sortOrder="-1" :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem"
      :pt="{
        header: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        }),
        root: () => ({
          class: 'border-2 border-gray-300 border-top-none'
        }),
      }">
      <Column field="ref_client" :header="$t('facture.refClient')" :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"></Column>
      <Column :header="$t('facture.totalTTC')" :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"><template #body="slotProps">
          {{ (Math.round(slotProps.data.total_ttc * 100) / 100).toFixed(2) }}
        </template></Column>
      <Column field="date" :header="$t('facture.date')" :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }">
        <template #body="slotProps">
          {{ getDateFormat(slotProps.data.date) }}
        </template>
      </Column>
      <Column field="note_public" :header="$t('facture.etat')" :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"><template #body="slotProps">
          <Tag :value="$t('facture.' + getEtat(slotProps.data.paye))" :severity="getSeverity(slotProps.data.paye)" />
        </template></Column>
        <Column field="last_main_doc" :header="$t('fichesTechniques.view')" :pt="{
        root: () => ({
          class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
        })
      }"><template #body="slotProps">
              <i @click="loadPdfModal(slotProps.data.last_main_doc, slotProps.data.id, slotProps.data.socid, slotProps.data.ref)" class="pi pi-eye cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem"></i>
          </template></Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="displayModalPdf" modal :header="headerModalPdf" :style="{ width: '70vw' }">
    <iframe :src="pdfData" width='100%' style="height:60vh;"></iframe>
  </Dialog>

  <Toast />
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      factures: null,
      idTiers: null,
      displayModalPdf: false,
      headerModalPdf: null,
    };
  },
  mounted() {
    // s'il y a un tiers payeur on prend son identifiant pour les factures 
    if(localStorage.getItem('idTiersPayeur') !== 'undefined'){
      this.idTiers = localStorage.getItem('idTiersPayeur');
    }
    else{
      this.idTiers = localStorage.getItem("idTiers");
    }
    //on récupère les factures liées au tiers stocké en localstorage 
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    axios
      .post(process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/factures", {
        idTiers: this.idTiers,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": "Bearer "+this.token,
        }
      })
      .then((response) => {
        //Permet d'éviter un vue warn qui préfère un tableau vide à la place d'un string vide 
        if (response['data'] !== "") {
          this.factures = response["data"];
        }
        else {
          this.factures = [];
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
            });
        }
      });
  },
  methods: {
    loadPdfModal(pathPdf, id, socid, ref){
      axios
        .post(process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/facture", {
          pathPdf: pathPdf,
          socid: socid,
          id: id,
          ref: ref
        }, {
          headers: {
            "Authorization": "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          },
        })
        .then((response) => {
          this.pdfData = "data:application/pdf;base64," + encodeURI(response.data);
          this.headerModalPdf = "Visualisation de " + ref;
          this.displayModalPdf = true;
        })
        .catch((e) => {
          // Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
              this.$toast.add({
                  severity: "error",
                  summary: this.$t('erreur.erreur'),
                  detail: this.$t('erreur.erreurInterne'),
                  life: 3000,
              });
          }
        });
      }
  }
};
</script>