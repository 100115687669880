<template>
  <Nav />
  <Connexion />
</template>
<script>
import Connexion from "@/components/Connexion.vue";
import Nav from '@/components/TopBar.vue';
export default {
  components: {
    Connexion,
    Nav
  },
};
</script>