<script setup>
import axios from "axios";
import { useToast } from "primevue/usetoast";

const toast = useToast();

</script>
<template>
    <div class="flex flex-column justify-content-around">
        <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between border-bottom-1 relative lg:static select-none"
            style="min-height: 5vh">
            <span class="text-indigo-600 mr-0 lg:mr-6 w-20rem cursor-pointer" @click="accueil()"><strong class="text-2xl">PHL Soft</strong> {{
                $t('topBar.clientSpace') }}</span>
            <a v-ripple class="cursor-pointer block lg:hidden text-700 mt-1 p-ripple" v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                leaveToClass: 'hidden',
                hideOnOutsideClick: true,
            }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div
                class="align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                <ul
                    class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                    <li class="lg:relative">
                        <a v-ripple
                            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-language mr-2"></i>
                            <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                        </a>
                        <ul
                            class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                            <li>
                                <a @click="francais()" v-ripple
                                    class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-language mr-2"></i>
                                    <span>Français</span>
                                </a>
                            </li>
                            <li>
                                <a @click="english()" v-ripple
                                    class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-language mr-2"></i>
                                    <span>English</span>
                                </a>
                            </li>
                            <!-- <li>
                                <a @click="italian()" v-ripple
                                    class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-language mr-2"></i>
                                    <span>Italiano</span>
                                </a>
                            </li> -->
                        </ul>
                    </li>
                    <li v-if="connecte" class="border-top-1 surface-border lg:border-top-none">
                        <a v-ripple
                            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            @click="deco">
                            <span>{{ $t('topBar.disconnect') }}</span>
                        </a>
                    </li>
                    <li v-else class="border-top-1 surface-border lg:border-top-none">
                        <a v-ripple
                            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            @click="revenir">
                            <span>{{ $t('topBar.comeBackToWebsite') }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-if="connecte"
        class="flex flex-wrap align-content-center justify-content-between vertical-align-middle gradientArmonie px-6 text-0 w-full text-center">
            <div class="flex align-items-center justify-content-center">
                <span class="sm:text-sm">{{ $t("topBar.hello") }}, {{ prenom }} {{ nom }}</span>
            </div>
            <div v-if="adminPhl">
                <span class="text-sm xl:w-6">
                    Entreprise actuelle : 
                    <strong class="white-space-nowrap">{{ nomEntreprise }}</strong>
                </span>
            </div>
            <div v-else class="flex justify-content-center w-8">
                <span class="text-sm xl:w-6">
                    {{ $t("topBar.administrativeContact") }} :
                    <strong class="white-space-nowrap"><a class="text-white" href='mailto:fbouchez@armonie.group'>Florence BOUCHEZ</a></strong>
                </span>
                <span class="text-sm xl:w-6">
                    {{ $t("topBar.commercialContact") }} :
                    <strong class="white-space-nowrap"><a class="text-white" :href="'mailto:' + mailCommercial">{{ prenomCommercial }} {{ nomCommercial }}</a>
                        {{ numCommercial }}</strong>
                </span>
            </div>
            <div class="flex w-1"></div>
    </div>
    <div v-if="factureImpayee" class="flex gradientArmonie text-0">
        <div class="w-2"></div>
        <div class="w-9">
            <h1 class="text-2xl">Oups...</h1>
            <p class="text-sm">
                {{ $t('topBar.gotUnPaidInvoices') }}
            </p>
            <p class="cursor-pointer underline" @click="testClick"></p>
        </div>
    </div>
    <Toast />
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            prenom: "",
            nomCommercial: "",
            prenomCommercial: "",
            numCommercial: "",
            mailCommercial: "",
            nom: "",
            idTiers: null,
            factureImpayee: false,
            connecte: false,
            adminPhl: false,
            nomEntreprise: ""
        };
    },
    mounted() {
        //on vérifie qu'il est connecté et l'état de ses factures
        this.connecte = localStorage.getItem("connecte") !== undefined && localStorage.getItem("connecte") !== null && localStorage.getItem("connecte") !== "" && localStorage.getItem("connecte") === "true";
        this.factureImpayee = localStorage.getItem("factureImpayee") !== undefined && localStorage.getItem("factureImpayee") !== null && localStorage.getItem("factureImpayee") !== "" && localStorage.getItem("factureImpayee") === "true";
        //récupération du token backend dans le local storage
        this.token = localStorage.getItem("token");
        this.nomEntreprise = localStorage.getItem("nomEntreprise");
        this.adminPhl = localStorage.getItem("adminPhl") === 'true';

        if (this.connecte) {
            this.nom = localStorage.getItem("nom");
            this.prenom = localStorage.getItem("prenom");
            this.idTiers = localStorage.getItem("idTiers");

            //on récupère les informations du commercial rattaché 
            axios
                .post(
                    process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/infosCommercial",
                    {
                        idTiers: this.idTiers,
                    },
                    {
                        headers: {
                            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer "+this.token,
                        },
                    }
                )
                .then((response) => {
                    if(response.data.length !== 0 && response["data"][0]["lastname"] !== null && response["data"][0]["firstname"] !== null){
                        this.nomCommercial = response["data"][0]["lastname"];
                        this.prenomCommercial = response["data"][0]["firstname"];
                        this.numCommercial = response["data"][0]["phone"];
                        this.mailCommercial = response["data"][0]["email"];
                    } else {
                        this.nomCommercial = "ROUTIER";
                        this.prenomCommercial = "Guy";
                        this.mailCommercial = "groutier@armonie.group"
                    }
                }).catch((e) => {
                    //Token invalide ou expiré
                    if(e['response']['status'] === 401){
                        this.$router.push("/login");
                    }
                    //capture des erreurs revoyées par le back-end
                    else{
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t('erreur.erreur'),
                        detail: this.$t('erreur.erreurInterne'),
                        life: 3000,
                    });
                    }
                });
        }
    },
    methods: {
        revenir() {
            window.location.href = "https://www.phlsoft.com/";
        },
        deco() {
            //il faut mettre en place la sauvegarde si la checkbox est cochée à la connexion
            localStorage.clear();
            this.$router.push("/login");
        },
        contact() {
            window.open('https://www.armonie.group/contact/', '_blank');
        },
        accueil() {
            this.$router.push("/accueil");
        },
        english() {
            localStorage.setItem('appLanguage', 'en')
            location.reload();
        },
        italian() {
            localStorage.setItem('appLanguage', 'it')
            location.reload();
        },
        francais() {
            localStorage.setItem('appLanguage', 'fr')
            location.reload();
        },
    },
};
</script>
  