<template>
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7">
        <div class="text-center lg:text-right">
            <div class="mt-6 mb-3 font-bold text-6xl text-900">{{ $t('404.areYouLost') }} ?</div>
            <p class="text-700 text-3xl mt-0 mb-6">{{ $t('404.unavailablePageOrNotFind') }}</p>
            <!-- vérifier si le connecté à des factures impayées pour éviter de le rediriger à partir de l'accueil -->
            <router-link v-if="impayee" :to="'/factureImpayee'">
                <Button type="button" :label="$t('404.backToPreviousPage')" class="p-button-outlined"/>
            </router-link>
            <router-link v-else-if="register" :to="'/accueil'">
                <Button type="button" :label="$t('404.backToHome')" class="p-button-outlined"/>
            </router-link>
            <router-link v-else :to="'/login'">
                <Button type="button" :label="$t('connexion.connectionTitle')" class="p-button-outlined"/>
            </router-link>
        </div>
        <div>
            <img src="@/assets/notFound.png" alt="Image" class="w-full md:w-18rem" />
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            register: "",
            impayee: ""
        }
    },
    mounted() {
        //on vérifie que les variables existent dans le local storage et qu'elles ne sont pas vides
        this.register = localStorage.getItem("connecte") !== undefined && localStorage.getItem("connecte") !== null && localStorage.getItem("connecte") !== "" && localStorage.getItem("connecte") === "true";
        this.impayee = localStorage.getItem("impayee") !== undefined && localStorage.getItem("impayee") !== null && localStorage.getItem("impayee") !== "" && localStorage.getItem("impayee") === "true";
    }
}
</script>